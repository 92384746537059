import React, { useState, useEffect } from 'react'
import { Container,Form } from 'react-bootstrap'
import { IoHomeOutline } from 'react-icons/io5'
import Select from "react-select";
import DataTable from "react-data-table-component";
import { Op_per } from "../../utils/Permissions";
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy';
import { GetMoodsByUserId } from '../../utils/proxy/DayInOutProxy';
import Swal from 'sweetalert2';
import { UserMoods } from '../../utils/constant';

export default function () {

    const months = [
        { label: "January", value: "01" },   
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }
    
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [data, setData] = useState([]);
    const [mood,setMood] = useState(""); //To set mood emojis beside the mood name 

    const [size, setSize] = useState(10);
    const [page,setPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState();

    

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            } 
        };
        getallusers();
    }, []);

    useEffect( ()=> {
        const getMoodsByUserId = async () => {

            let getMoods = await GetMoodsByUserId(userid, selectedYear.value+"-"+selectedMonth.value, page, size);
           
            
            if(getMoods.moods){
                setData(getMoods.moods);
                setTotalRows(getMoods.totalrows);
                
            } else {
                Swal.fire({
                    title: "Error !",
                    text: " Something went wrong",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
           
        }
        getMoodsByUserId();
    },[userid,selectedMonth,selectedYear,page,size]);

    const handleSelectChange = (selected) => {
        setUserId(selected.value);
        setSelectedUser(selected);
    };
    const handleSelectChangeMOnth = (selected) => {
        setSelectedMonth(selected);
    };
    const handleSelectChangeYear = (selected) => {
         setSelectedYear(selected);
    };
    const handlePerRowChange = (newPerPage, page) =>{
        setSize(newPerPage);
        setPage(page-1);
    };
    const handlePageChangeTable = (page) =>{
        setPage(page-1);
    };

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const columns = [
        {
            name: "Date",
            selector: (row) => row.date,
            margin: 30,
            width: "10%",
        },
        {
            
            name: "Start Day Mood",
            selector: (row) => <div style={{ whiteSpace: 'normal',fontSize:'10px', backgroundColor: row.startdaymood === "" || row.startdaymood === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.startdaymood === "" || row.startdaymood === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.startdaymood === "" || row.startdaymood === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.startdaymood == "Bad" ? UserMoods[4].mood:'' || row.startdaymood == "Poor" ? UserMoods[3].mood:'' || row.startdaymood == "Neutral" ? UserMoods[2].mood:'' || row.startdaymood == "Good" ? UserMoods[1].mood:'' || row.startdaymood == "Perfect" ? UserMoods[0].mood:''}{row.startdaymood ?  row.startdaymood : "N/A"}</div>,
            width: "12%",

        },
        {
            name: "End day mood",
            selector: (row) => <div style={{whiteSpace: 'normal', backgroundColor: row.enddaymood === "" || row.enddaymood === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.enddaymood === "" || row.enddaymood === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.enddaymood === "" || row.enddaymood === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.enddaymood == "Bad" ? UserMoods[4].mood:'' || row.enddaymood == "Poor" ? UserMoods[3].mood:'' || row.enddaymood == "Neutral" ? UserMoods[2].mood:'' || row.enddaymood == "Good" ? UserMoods[1].mood:'' || row.enddaymood == "Perfect" ? UserMoods[0].mood:''}{row.enddaymood ? row.enddaymood : "N/A"}</div>,
            width: "12%",
        },
    ]

  return (
  <Container fluid style={{ minHeight: "80vh" }}>
    <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/mood-report"><span class="icon icon-beaker"> </span>Masters</a></li>

                        <li><a href="/mood-report"><span class="icon icon-double-angle-right"></span>Mood Report</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
            <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px", display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Month</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={months}
                                    value={selectedMonth}
                                    onChange={handleSelectChangeMOnth}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "450px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Year</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={years}
                                    value={selectedYear}
                                    onChange={handleSelectChangeYear}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowChange}
                        onChangePage={handlePageChangeTable}
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        dense
                    />
                </div>
            </div>
  </Container>
  )
}
