import Restapi from "../api/Restapi";
import { REPORTING_URL, constats } from "../constant";

// Charts

export const GetAllBifurcationCounts = async () => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/counts", null);
};

export const GetAllBifurcationCountsUser = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + "/apiv1/dashboard/user/counts?userid="+userid, null);
};

export const GetAllDcrYearlyMonthly = async (userid,year,month) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/dcr/user/${userid}/${month}/${year}`, null);
};

export const GetTpAchievements = async (userid,month,year) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/tp/achievment/${userid}/${month}/${year}`, null);
};

export const GetNotSubmittedDCR = async (userid,month,year) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/dcr/notsubmitted/${userid}/${month}/${year}`, null);
};

export const GetPendingForAdditionDCR = async (userid,month,year) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/dcr/pendingforadd/${userid}/${month}/${year}`, null);
};

export const GetDCRCountStateWise = async (month,year) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/dcr/count/statewise/${month}/${year}`, null);
};

export const GetDCRCountOfMonth = async (userid,month,year) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/dcr/count/month/${userid}/${month}/${year}`, null);
};

export const GetUserList = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/userlist/${userid}`, null);
};

export const GetFieldForceStatusReport = async (userid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/fieldforce/report/${userid}`, null);
};

export const GetVisitSummary = async (userid,from,to,type) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/visitanalysis/report/${userid}/${from}/${to}/${type}`, null);
};

export const GetMetVisitSummary = async (userid,from,type) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/visitanalysis/met/${userid}/${from}/${type}`, null);
};

export const GetDoctorDegreeOrSpeciality = async (userid,type) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/doctor/category/${userid}/${type}`, null);
};

export const GetTerritoryStatus = async (stateid) => {
  const [AUTH_URL, REPORTING_URL] = await constats();
  return await Restapi("get", REPORTING_URL + `/apiv1/reports/territory/status/${stateid}`, null);
};