import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import {
  DeleteUserTeritory,
  GetAllUserTeritory,
  GetAllUserTeritoryByFilter,
  UpdateUserTeritory,
} from "../../../utils/proxy/Userteritoryproxy";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../../utils/proxy/Authproxy";
import Addarea from "./Addarea";
import UpdateStatus from "./UpdateStatus";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Parser } from "@json2csv/plainjs";
import { unwind, flatten } from "@json2csv/transforms";
import { HiDotsVertical } from "react-icons/hi";
import { IoHomeOutline } from "react-icons/io5";
import Helper from "../../../utils/helperFunctions";
import Swal from "sweetalert2";
import { ClinicBulkUpdateArea } from "../../../utils/proxy/Clinicproxy";
import { SiDatabricks } from "react-icons/si";
import { GetAllAreasDetail, GetAllCitiesDetail, GetAllCountries, GetAllStatesDetail } from "../../../utils/proxy/Countryproxy";
import { Op_per } from "../../../utils/Permissions";

function StockistMaster() {
  const [userteritory, setUserTeritory] = useState([]);
  const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : "all");
  const [areatype, setAreaType] = useState("all");
  const [status, setStatus] = useState("all");
  const [selectedstatus, setselectedStatus] = useState();
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState(localStorage.getItem('role')==='User' ? {value:localStorage.getItem('uuid'),label:localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')+" (Self)"} : null);
  const [users, setUsers] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [showbulkareaedit, setShowBulkAreaEdit] = useState(false);
  const [bulkSourceData, setBulkSourceData] = useState([]);
  const [selectedRow,setselectedRow] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const filteredData = userteritory.filter((row) =>
    row.areaname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // bulk edit constants
  
  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallcitys = async () => {
      let citysdata = await GetAllCitiesDetail();
      if (citysdata) {
        // setCitys(citysdata);
      } else {
        alert("Error", "Issue While Fetching City");
      }
    };
    // getallcitys();

    const getallareas = async () => {
      let areas = await GetAllAreasDetail();
      if (areas) {
        // setAreas(areas);
      } else {
        alert("Error", "Issue While Fetching Teritories");
      }
    };
    // getallareas();

    const getallcountries = async () => {
      let countriesdata = await GetAllCountries();
      if (countriesdata) {

        setCountries(countriesdata);
      } else {
        alert("Error", "Issue While Fetching Countries");
      }
    };
    getallcountries();

    const getallstates = async () => {
      let statesdata = await GetAllStatesDetail();
      if (statesdata) {
        setStates(statesdata);
      } else {
        alert("Error", "Issue While Fetching State");
      }
    };
    getallstates();
  }, [show]);

  const handleBulkUpdateSourceData = () => {
    let newArr = [];
    selectedRows.map((obj) => {
      let option = {

        // ...obj,
        id: obj.id,
        country:{id:obj.country_id,name:obj.countryname},
        state: {id:obj.stateid,name:obj.statename},
        city: obj.cityname,
        teritory: obj.areaname,
        teritorycode: obj.areacode,
        teritorytype: obj.areatype,
        status: obj.areastatus,
      };
      newArr.push(option);
      return true;
    });
    setBulkSourceData(newArr);
    setShowBulkAreaEdit(true);
  };

  let handleBulkAreaUpdate = async () => {
    let newData = bulkSourceData.map((data) => {
      return {
        id: data.id,
        countryname: data.countryname.id,
        statename: data.statename.id,
        cityname: data.cityname.id,
        areaname: data.areaname.id,
        areacode: data.areacode.id,
        areatype: data.areatype.id,
        areastatus: data.areastatus.id,
      };
    });

    let response = await ClinicBulkUpdateArea(newData);
    if (response) {
      // SetReload(true);
      setShowBulkAreaEdit(false);

      Swal.fire({
        title: "Success!",
        text: "Territory Bulk Updated !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Updating Territory");
    }
  };


  const handleSaveEdit = async () => {
    // alert(selectedstatus.value);
    let rs  = await UpdateUserTeritory(selectedRow.id,selectedstatus.value)
    if (rs) {
      setReload(true)
      alert("Status Updated")
    } else {
      alert("Error While Updating Status")
    }
  }
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleStatusChange = (selected) => {
    setselectedStatus(selected);
  };

  const closeStatusModal = () => {
    setShowStatusModal(false);
  };

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallteritories = async (userid, areatype, status) => {
      let userteritories = await GetAllUserTeritoryByFilter(
        userid,
        areatype,
        status
      );
      if (userteritories) {
        setUserTeritory(userteritories);
      } else {
        alert("Error", "Issue While Fetching User Teritories");
      }
    };
    getallteritories(userid, areatype, status);
    setReload(false);
  }, [reload, userid, showModal]);

  useEffect(() => {
    // Fetch data from an API when the component mounts
    const getallusers = async () => {
      var allusers;
      if(localStorage.getItem('role') === 'Admin'){
        allusers = await AllUsers();
      }else if(localStorage.getItem('role') === 'Manager'){
        allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
      }
      if (allusers) {
        setUsers(allusers);
      } else {
        alert("Error", "Issue While Fetching Users");
      }
    };
    if(localStorage.getItem('role') != 'User'){
      getallusers();
      }
  }, []);

  const options = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

   const statusoptions = [{
    value: "ACTIVE",
    label: "APPROVED"
  },
  {
    value: "INACTIVE",
    label: "INACTIVE"
  },
  {
    value: "PENDING_FOR_ADDITION",
    label: "PENDING FOR ADDITION"
  },
  {
    value: "PENDING_FOR_DELETION",
    label: "PENDING FOR DELETION"
  }];

  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   width:"5%",
    //   sortable: true,
    // },
    {
      name: "Country",
      selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor: row.countryname === "" || row.countryname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.countryname === "" || row.countryname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.countryname === "" || row.countryname === null ? "#fff": "#2457C5", margin:"3px", }}>{row.countryname ? row.countryname : "N/A"}</div>,
      
      width:"7%",
      sortable: true,
      fontSize: 10,
      fontWeight:'bold' ,
    },
    {
      name: "State",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.statename === "" || row.statename === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.statename === "" || row.statename === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.statename === "" || row.statename === null ? "#fff": "#2457C5", margin:"3px", }}>{row.statename ? row.statename : "N/A"}</div>,
      
      width:"10%",
      sortable: true,
      fontSize: 10,
      fontWeight:'bold',
    },
    {
      name: "City",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor:  row.cityname === "" ||  row.cityname === null ? "red":"#D9E1FF", borderRadius:"10px", border:  row.cityname === "" ||  row.cityname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.cityname === "" ||  row.cityname === null ? "#fff": "#2457C5", margin:"3px", }}>{ row.cityname ?  row.cityname : "N/A"}</div>,
      width:"10%",
    },
    {
      name: "Territory",
      selector: (row) =>  <div style={{ whiteSpace: 'normal',backgroundColor: row.areaname === "" || row.areaname === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.areaname === "" || row.areaname === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.areaname === "" || row.areaname === null ? "#fff": "#2457C5", margin:"3px", }}>{row.areaname ? row.areaname : "N/A"}</div>,
      width:"10%",
    },
    {
      name: "Territory Code",
      selector: (row) =><div style={{ whiteSpace: 'normal',backgroundColor: row.areacode === "" || row.areacode === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.areacode === "" || row.areacode === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.areacode === "" || row.areacode === null ? "#fff": "#2457C5", margin:"3px", }}>{row.areacode ? row.areacode : "N/A"}</div>,
      width:"10%",
    }, 
    
    {
      name: "Territory Type",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.areatype === "" || row.areatype === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.areatype === "" || row.areatype === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.areatype === "" || row.areatype === null ? "#fff": "#2457C5", margin:"3px", }}>{row.areatype ? row.areatype : "N/A"}</div>,
      width:"10%",
    },
    {
      name: "Status",
      selector: (row) => <div style={{ whiteSpace: 'normal',backgroundColor: row.areastatus === "" || row.areastatus === null ? "red":"#D9E1FF", borderRadius:"10px", border: row.areastatus === "" || row.areastatus === null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color: row.areastatus === "" || row.areastatus === null ? "#fff": "#2457C5", margin:"3px", }}>{row.areastatus ? row.areastatus === 'ACTIVE' ? 'Approved' : row.status : "N/A"}</div>,
      width:"10%",
    },
    // {
    //   name: "Actions",
    //   selector: (row) => (
    //     <div key={row.id}>
    //       <AiTwotoneEdit
    //         style={{ color: "black", fontSize: 20 }}
    //         onClick={() => handleEditShow(row)}
    //       />
    //       &nbsp;&nbsp;&nbsp;
    //       <AiFillDelete
    //         onClick={() => handleDelete(row.id)}
    //         style={{ color: "red", fontSize: 20 }}
    //       />
    //     </div>
    //   ),
    // },
  ];

  if(Op_per.get().USERS_USER_TERRITORIES.ALLOW_DELETE || Op_per.get().USERS_USER_TERRITORIES.ALLOW_EDIT){
    columns.push({
      name: "Actions",
      selector: (row) => (
        <div key={row.id}>
          <AiTwotoneEdit
            style={{ color: "black", fontSize: 20, display:Op_per.get().USERS_USER_TERRITORIES.ALLOW_EDIT ? '' : 'none' }}
            onClick={() => handleEditShow(row)}
          />
          &nbsp;&nbsp;&nbsp;
          <AiFillDelete
            onClick={() => handleDelete(row.id)}
            style={{ color: "red", fontSize: 20, display:Op_per.get().USERS_USER_TERRITORIES.ALLOW_DELETE ? '' : 'none' }}
          />
        </div>
      ),
    })
  }

  const customStyles = {   head: {     style: {       backgroundColor: 'blue',     },   }, };
  const CustomHeader = ({ children }) => (   <div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

  const handleEditShow = (row) => {
    setselectedRow(row);
    setShow(true);
  };

  // const handleEditSave = async () => {
  //   setShowEdit(false);
  //   let res = await UpdateUserTeritory(editRow.id,selectedStatus.value)
  //   if (res) {
      
  //     setReload(true)
  //     alert("Status Updated")
      
  //   } else {
  //     alert("Error While Updating Status")
      
  //   }
  // }

  const handleDelete = async(row) => {
    let res = await DeleteUserTeritory(row)
    if (res) {
      
      setReload(true)
      alert("Deleted successfully")
      
    } else {
      alert("Error While Updating Status")
      
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedUser(selected);
    setUserId(selected.value);
  };

  async function downloadCSV(userteritory) {
    let csv;
    try {
      const opts = {
        fields: [
          "id",
          "countryname",
          "statename",
          "cityname",
          "areaname",
          "areacode",
          "areatype",
          "areastatus",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(userteritory);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>Export All</Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(userteritory)} />,
    [userteritory]
  );

  return (
    <Container fluid style={{ minHeight: "80vh" }}>
      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding:0,
          backgroundColor:'#3887BE'
        }}
      >

<div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
<ul id="breadcrumb">
  <li><a href="/"><IoHomeOutline
            style={{ color: "white", fontSize: 20 }}
            
          /></a></li>
  <li><a href="/user-teritory"><span class="icon icon-beaker"> </span>Users</a></li>
  <li><a href="/user-teritory"><span class="icon icon-beaker"> </span> User Territories</a></li>
  {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}
  
</ul>
	
</div>
      </div>

      <div className="glass-body" style={{ margin: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "350px" }}>
          {Op_per.get().USERS_USER_TERRITORIES.USER_DROPDOWN ? <Form.Group
              className="mb-3 d-flex w-100"
              style={{ gap: "15px" }}
              controlId="formBasicPassword"
            >
              <Form.Label className="mt-2">Users</Form.Label>
              <div className="w-75">
                <Select
                className="selectdoctor"
                  options={options}
                  value={selectedUser}
                  onChange={handleSelectChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
              </div>
            </Form.Group> : "" }
          </div>
          <div>
            <Form.Control
              style={{
                height: 46,
                width: 250,
                borderColor: '#28B3D2',
                borderWidth: 2,
                paddingHorizontal: 8,
                marginBottom: 16,
                borderTopLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}
              className="glass"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
          {selectedRows.length > 0 && Op_per.get().USERS_USER_TERRITORIES.ALLOW_EDIT ? (
              <Dropdown style={{ marginRight: 10 }}>
                <Dropdown.Toggle variant="warning" title="Bulk Menu" id="dropdown-basic">
                  <SiDatabricks />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleBulkUpdateSourceData()}>
                    Update
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              ""
            )}
          <div>
          <div class="dropdown">
                <button
                  class="btn btn-secondary"
                  type="button"
                  style={{ backgroundColor: "#28B3D2", display:Op_per.get().USERS_USER_TERRITORIES.ALLOW_ADD ? '' : 'none' }}
                  title="Options"
                  id="dropdown-basic"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <ul class="dropdown-menu">
                  <li onClick={openModal} style={{display:Op_per.get().USERS_USER_TERRITORIES.ALLOW_ADD ? '' : 'none'}}>
                    <a class="dropdown-item">Add</a>
                  </li>
                 
                </ul>
              </div>
          </div>
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            columns={columns}
            data={filteredData}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) =>
              setSelectedRows(selectedRows)
            }
            // actions={actionsMemo}
            pagination
            customStyles={{headRow: {style: {backgroundColor: '#E5E1DA',  padding:7 }, }  }}
                        CustomHeader={<CustomHeader/>}
            style={{ marginTop: 10 }}
            dense
          />
        </div>
      </div>

      <Addarea showModal={showModal} closeModal={closeModal} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form.Group className="mb-3" >
        <Form.Label>Status <span style={{color:"red"}}>*</span></Form.Label>
        <Select
        className="selectdoctor"
                  options={statusoptions}
                  value={selectedstatus}
                  onChange={handleStatusChange}
                  styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
                />
      </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {showbulkareaedit && (
        <Modal
          fullscreen={true}
          size="lg"
          show={true}
          onHide={() => setShowBulkAreaEdit(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bulk Update Territory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="scrollable-div">
              <Table
                style={{ tableLayout: "auto", width: "100%" }}
                className="blazetable"
                striped
                bordered
                hover
                size="sm"
              >
                <thead className="sticky">
                  <tr style={{ width: "auto", whiteSpace: "nowrap" }}>
                    {Object.keys(bulkSourceData[0]).map((row, index) => {
                      return <th className={index === 0 ? "sticky-col1" : index === 1 ? "sticky-col2" : ""}>{row}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bulkSourceData.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((cell, cellIndex) => {
                          return (
                            <td
                              style={{ width: "auto", whiteSpace: "nowrap" }}
                              key={cellIndex}
                              className={cellIndex === 0 ? "sticky-col1" : ""}
                            >
                              {cell === "country" || cell === "state" || cell === "cityname" || cell == "areaname" || cell == "areacode" || cell == "areatype" || cell == "areastatus" ? (
                                <div style={{ minWidth: "200px" }}>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={
                                      cell === "country" ? countries  : cell === "states" ? states : options
                                    }
                                    value={bulkSourceData[rowIndex][cell]}
                                    getOptionLabel={(option) => cell === "country" ? option.name : cell === "state" ? option.statename : cell === "city" ? option.cityname : option.label}
                                    getOptionValue={(option) => cell === "country" || cell == "state" || cell == "city" ? option.id : option.value}
                                    onChange={(selectedOption) => {
                                      const newValue = selectedOption;
                                      setBulkSourceData((prevData) => {
                                        const newData = [...prevData];
                                        newData[rowIndex][cell] = newValue;
                                        return newData;
                                      });
                                    }}
                                    isMulti={cell !== 'country' && cell !== 'state' && cell !== 'city'}
                                  />
                                </div>
                              ) : (
                                <input
                                  type="text"
                                  name={cell}
                                  value={
                                    bulkSourceData[rowIndex][cell] !== null &&
                                      bulkSourceData[rowIndex][cell] !== undefined
                                      ? bulkSourceData[rowIndex][cell]
                                      : ""
                                  }
                                  disabled={cell === "id"}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    setBulkSourceData((prevData) => {
                                      const newData = [...prevData];
                                      newData[rowIndex][cell] = newValue;
                                      return newData;
                                    });
                                  }}
                                />
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowBulkAreaEdit(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleBulkAreaUpdate}>
              Update Teritories
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default StockistMaster;
