import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Button, Form, Modal } from 'react-bootstrap'
import { AllUsers, GetAllManagerUsers } from '../../utils/proxy/Authproxy'
import { useEffect, useState } from 'react'
import Select from "react-select";
import moment from 'moment'
import { GetAllTaskForCalendar, GetAttendance, GetDcrTask } from '../../utils/proxy/AttendanceProxy'
import { Parser } from "@json2csv/plainjs";
import React from 'react'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { GetAllProjects, GetAllProjectsByUserid } from '../../utils/proxy/ProjectProxy'
import { ChangeTaskStatus, CreateTask, GetAllTaskCategory, GetTaskById, UpdateTaskById, UpdateTaskTimingById } from '../../utils/proxy/Taskproxy'
import Swal from 'sweetalert2'
import { useCallback } from 'react'
import { IoHomeOutline } from 'react-icons/io5'
import LoadingComponent from '../Includes/LoadingComponent'
import { Op_per } from '../../utils/Permissions'
import { useLocation } from 'react-router-dom'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const DragAndDropCalendar = withDragAndDrop(Calendar)

function Attendance({date}) {

  const { state } = useLocation();
  const [users, setusers] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState((localStorage.getItem('role') === 'User' || state != null) ? { value: localStorage.getItem('uuid'), label: localStorage.getItem('firstname') + " " + localStorage.getItem('lastname') + " (Self)" } : null);
  const [selectedUserId, setSelectedUserId] = useState((localStorage.getItem('role') === 'User' || state != null) ? localStorage.getItem('uuid') : null);
  const [reload, setReload] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [event, setEvent] = useState([]);
  const [showedit, setshowedit] = useState(false);

  const [utcDate, setUtcDate] = useState('');
  const [utcEndDate, setUtcEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState(state!=null ? new Date(state.dateProp) : null);
  const [view, setView] = useState(state!=null ? 'day' : 'month');
  const [allProject, setAllProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [showaddtask, setshowaddtask] = useState(false);
  const [taskStatus, setTaskStatus] = useState(null);
  const [task, setTask] = useState({
    "id": null,
    "userid": selectedUserId,
    "task": null,
    "projectid": selectedProjectId,
    "categoryid": selectedCategoryId,
    "start": '',
    "end": '',
    "description": null,
    "createdby": localStorage.getItem('uuid'),
    "priority": null,
    "status": "new"
  });
  const [currentmodaldata, setCurrentModaldata] = useState({ start: '', end: '' });
  const [utcStartDate, setUtcStartDate] = useState('');

  useEffect(() => {
    async function getProjects() {
      let data = await GetAllProjectsByUserid(selectedUserId);
      if (data) {
        setAllProject(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    async function getCategories() {
      let data = await GetAllTaskCategory();
      if (data) {
        setAllCategories(data);
      } else {
        alert('Unable to Fetch');
      }
    }

    getProjects();
    getCategories();
  }, [selectedUserId])

  const localizer = dayjsLocalizer(dayjs)

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '';

    // Change color based on event title
    // switch (event.dcrtype) {
    //   case 'doctor':
    //     backgroundColor = '#E97451	';
    //     break;
    //   case 'clinic':
    //     backgroundColor = 'yellow';
    //     break;
    //   case 'chemist':
    //     backgroundColor = 'aqua';
    //     break;
    //   case 'stockist':
    //     backgroundColor = 'red';
    //     break;
    //   case 'hospital':
    //     backgroundColor = 'golden';
    //   // Add more cases for other titles if needed
    //   default:
    //     backgroundColor = '#DAF7A6';
    // }
    if (event.task != null && event.status == 'completed') {
      backgroundColor = '#198754';
    } else if (event.task != null && event.status == 'inprogress') {
      backgroundColor = '#FFC107';
    } else if (event.task != null && event.status == 'new') {
      backgroundColor = '#0D6EFD';
    }

    const style = {
      backgroundColor,
      color: 'white',
      borderRadius: '5px',
      border: 'none',
    };

    return {
      style
    };
  };

  const handleEventClick = async (event) => {
    if (event.task && Op_per.get().TASKS.ALLOW_EDIT && event.status !== 'completed') {
      let d = await GetTaskById(event.taskid);
      setTask({ ...task, id: event.taskid })
      if (d) {
        setCurrentModaldata(d);
        setSelectedProject({ label: d.project, value: d.projectid })
        setSelectedProjectId(d.projectid)
        setSelectedCategory({ label: d.categoryname, value: d.categoryid })
        setSelectedCategoryId(d.categoryid)
        setUtcStartDate(dateToIsoStringWithoutChange(new Date(d.start)))
        setUtcEndDate(dateToIsoStringWithoutChange(new Date(d.end)))
        setTaskStatus(d.status)
      }
      // setCurrentModaldata({ ...currentmodaldata, task: event.task })
      setshowedit(true);
    }
  }


  const handleNavigate = (newDate, newView, action) => {
    if (action === 'DATE') {
      setSelectedDate(newDate);
    }
    setView(newDate);
  };

  const onNavigate = useCallback((newDate) => {
    setSelectedDate(newDate)
    var start = dateToIsoStringWithoutChange(new Date(newDate));
    const sd = start.split('T')[0].split('-')[0] + "-" + start.split('T')[0].split('-')[1] + "-" + "01";
    setStartDate(sd);
    var end = addMonths(new Date(sd), 1);
    const ed = end.split('T')[0].split('-')[0] + "-" + end.split('T')[0].split('-')[1] + "-" + "01";
    setEndDate(ed);
  }, [setSelectedDate])

  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date.toISOString();
  }

  const dayPropGetter = date => {
    const dayEvents = attendance.filter(event =>
      moment(event.start).isSameOrAfter(moment(date).startOf('day')) &&
      moment(event.end).isSameOrBefore(moment(date).endOf('day'))
    );

    const isAbsent = dayEvents.some(event => event.working === 'Absent')
    const isPresent = dayEvents.some(event => event.working === 'Present')
    const isHoliday = dayEvents.some(event => event.working === 'Holiday')


    const style = {
      backgroundColor: isAbsent ? '#ed0c3140' : isPresent ? '#0ca90c4d' : isHoliday ? '#ffff00cc' : '#fff', // Change the date cell color based on events
    };

    return {
      style
    };
  };

  useEffect(() => {
    var start = new Date().toISOString();
    const sd = start.split('T')[0].split('-')[0] + "-" + start.split('T')[0].split('-')[1] + "-" + "01";
    setStartDate(sd);
    var end = addMonths(new Date(sd), 1);
    const ed = end.split('T')[0].split('-')[0] + "-" + end.split('T')[0].split('-')[1] + "-" + "01";
    setEndDate(ed);
    const getAllUsersData = async () => {
      if (localStorage.getItem('role') === 'Admin') {
        let allusers = await AllUsers();
        allusers.push({ firstname: localStorage.getItem('firstname'), lastname: localStorage.getItem('lastname'), userid: localStorage.getItem('uuid'), username: localStorage.getItem('username') })
        setusers(allusers);
      } else if (localStorage.getItem('role') === 'Manager') {
        let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
        setusers(allusers);
      }
    };
    getAllUsersData();
  }, []);

  useEffect(() => {
    async function name() {
      if (selectedUserId != null) {
          let data = await GetAttendance(selectedUserId, startDate, endDate);
          if (data) {
            setAttendance(data);
  
          } else {
            alert("Unable To Fetch ");
          }
        let task = await GetDcrTask(selectedUserId, new Date(startDate).toISOString())
        let task1 = await GetAllTaskForCalendar(selectedUserId, new Date(startDate).toISOString())

        const t = [];

        if (task) {
          task.map((d) => {
            const d1 = {
              start: new Date(d.start),
              end: new Date(d.end),
              title: d.title + " " + d.dcrtype,
              allDay: d.dcrtype == ''
            }
            t.push(d1);
          })
          task1.map((d) => {
            const d2 = {
              taskid: d.taskid,
              start: new Date(d.start),
              end: new Date(d.end),
              title: d.task,
              task: d.task,
              status: d.status,
              isResizable:d.status != 'completed' ? true : false,
              isDraggable:(d.status != 'completed' || view === 'month') ? true : false
            }
            t.push(d2);
          })
          setEvent(t);
        } else {
          alert("Unable To Fetch Dcr Task");
        }
      }
    }

    name();
    setReload(false);
  }, [selectedUserId, startDate, reload, selectedDate]);

  const handleSelectChange = (selected) => {
    setTask({ ...task, userid: selected.value });
    setCurrentModaldata({ ...currentmodaldata, userid: selected.value });
    setSelectedUsers(selected);
    setSelectedUserId(selected.value);
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    )
  }

  const resizeEvent = async({ event, start, end }) =>{
    if(event.taskid){
      let d = await UpdateTaskTimingById(event.taskid,new Date(start).toISOString(),new Date(end).toISOString(),'update');
      if (d.status === 'success') {

        setEvent(prevEvents => {
          return prevEvents.map(pre => {
            if (pre.taskid === event.taskid) {
              return { ...pre, start: new Date(start), end: new Date(end) }; // Update start/end
            } else {
              return pre; // Leave other events unchanged
            }
          });
        });

      }else{
        Swal.fire({
          title:'Error',
          text:'Something Went Wrong',
          icon:'error'
        })
      }
    }
  }

  const eventDrop = async({ event, start, end }) =>{
    if(event.taskid){
      let d;
      if (view === 'month') {
        if (new Date(event.start)?.getDate() != new Date(start)?.getDate()) {
          d = await UpdateTaskTimingById(event.taskid,new Date(start).toISOString(),new Date(end).toISOString(),'new');
        }else{
          return;
        }
      }else{
        d = await UpdateTaskTimingById(event.taskid,new Date(start).toISOString(),new Date(end).toISOString(),'update');
      }
      
      if (d.status === 'success') {
        setReload(true);
      }else{
        Swal.fire({
          title:'Error',
          text:'Something Went Wrong',
          icon:'error'
        })
      }
    }
  }

  const handleDoubleClick = (event) => {
    let gd = new Date(event.start)
    setUtcDate(dateToIsoStringWithoutChange(new Date(event.start)));
    gd.setHours(gd.getHours() + 1);
    setTask({ ...task, start: new Date(event.start).toISOString(), end: gd.toISOString(), priority: 'medium' })
    setUtcEndDate(dateToIsoStringWithoutChange(gd));
    if(Op_per.get().TASKS.ALLOW_ADD) setshowaddtask(true);
  }

  function getLocalISOString(date) {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - (offset * 60 * 1000));
    return localDate;
  }

  const getUTCFromLocalISOString = (localISOString) => {
    const localDate = new Date(localISOString);
    const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60 * 1000));
    return utcDate.toISOString();
  }

  function dateToIsoStringWithoutChange(istDate) {
    if (!(istDate instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());

    const isoString = istDate.toISOString();

    return isoString;
  }

  const options = [];
  const projects = [];
  const category = [];

  users.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  allProject.map((project) => {
    projects.push({
      value: project.id,
      label: project.name
    })
  })

  allCategories.map((cat) => {
    category.push({
      value: cat.id,
      label: cat.name
    })
  })

  const handleProjectChange = (selected) => {
    if (selected === null) {
      setTask({ ...task, projectid: null });
      setCurrentModaldata({ ...currentmodaldata, projectid: null })
      setSelectedProject();
      setSelectedProjectId();
    } else {
      setTask({ ...task, projectid: selected.value });
      setCurrentModaldata({ ...currentmodaldata, projectid: selected.value })
      setSelectedProject(selected);
      setSelectedProjectId(selected.value);
    }

  };

  const handleCategoryChange = (selected) => {
    if (selected === null) {
      setTask({ ...task, categoryid: null })
      setCurrentModaldata({ ...currentmodaldata, categoryid: null })
      setSelectedCategory();
      setSelectedCategoryId();
    } else {
      setTask({ ...task, categoryid: selected.value })
      setCurrentModaldata({ ...currentmodaldata, categoryid: selected.value })
      setSelectedCategory(selected);
      setSelectedCategoryId(selected.value);
    }

  }

  const handlestatuschange = async (id, status) => {
    let d = await ChangeTaskStatus(id, status);
    if (d.status === 'success') {
      setReload(true)
      setshowedit(false)
      Swal.fire({
        title: "Success!",
        text: "Task Status Changed !!",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }

  const handlesubmit = async () => {
    if (task.task == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.userid == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter AssignTo !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.priority == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Priority !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.start == null || task.end == null || task.start == '' || task.end == '') {
      Swal.fire({
        title: "Warning!",
        text: "Enter Date fields !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (task.start > task.end) {
      Swal.fire({
        title: "Warning!",
        text: "Start Date is Greater Than End Date !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
    else {
      let d = await CreateTask(task);
      if (d.status === "success") {
        setshowaddtask(false);
        setReload(true)
        setTask({
          ...task,
          userid: selectedUserId,
          task: null,
          start: '',
          end: '',
          description: null,
          priority: null
        })
        Swal.fire({
          title: "Success!",
          text: "Task Added Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        alert("Error", "Issue While Adding Task");
      }
    }
  }

  const handleeditsubmit = async () => {
    if (currentmodaldata.task == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Title !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.description == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Description !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.userid == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter AssignTo !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.priority == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Task Priority !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.start == null || currentmodaldata.end == null) {
      Swal.fire({
        title: "Warning!",
        text: "Enter Date fields !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (currentmodaldata.start > currentmodaldata.end) {
      Swal.fire({
        title: "Warning!",
        text: "Start Date is Greater Than End Date !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      currentmodaldata.updatedat = '';
      let d = await UpdateTaskById(currentmodaldata.id, currentmodaldata);
      if (d.status === "success") {
        setshowedit(false);
        setReload(true);
        Swal.fire({
          title: "Success!",
          text: "Task Updated Successfully !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        alert("something went wrong");
      }
    }
  }

  async function downloadCSV(attendance) {
    let csv;
    try {
      const opts = {
        fields: [
          "username",
          "start",
          "working",
        ],
      };
      const parser = new Parser(opts);
      csv = parser.parse(attendance);
    } catch (err) {
      console.error(err);
    }

    const link = document.createElement("a");

    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  async function downloadCSV2() {
    const attendanceArrays = [];
    const attendancePromises = users.map(user => GetAttendance(user.userid, startDate, endDate));

    const results = await Promise.all(attendancePromises);
    results.forEach(data => {
      if (data) {
        attendanceArrays.push(data);
      } else {
        alert("Unable To Fetch ");
      }
    });
    let csv;
    try {
      // Collect all unique dates from the attendance data
      const datesSet = new Set();
      attendanceArrays.forEach(userAttendance => {
        userAttendance.forEach(record => {
          datesSet.add(record.start);
        });
      });
      const dates = Array.from(datesSet).sort(); // Convert to array and sort dates
  
      // Create a map to hold attendance by username and date
      const attendanceMap = {};
      attendanceArrays.forEach(userAttendance => {
        userAttendance.forEach(record => {
          if (!attendanceMap[record.username]) {
            attendanceMap[record.username] = {};
          }
          attendanceMap[record.username][record.start] = record.working;
        });
      });
  
      // Create the header row: "username", followed by all dates
      const fields = ["username", ...dates];
      csv = fields.join(",") + "\n";
  
      // Iterate over the attendance map and build CSV rows
      for (const [username, records] of Object.entries(attendanceMap)) {
        const row = [username, ...dates.map(date => records[date] || "")];
        csv += row.join(",") + "\n";
      }
    } catch (err) {
      console.error(err);
    }
  
    // Create a download link and trigger the download
    if (csv == null) return;
  
    const link = document.createElement("a");
    const filename = "attendance_export.csv";
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  

  const handleSelectSlot = ({ start }) => {
    // Handle the slot click, navigate to the day view by updating the selectedDate state
    if(view === 'month'){
      setSelectedDate(start);
      setView('day');
    }
    // You can use a router or another method to navigate to the day view
    // For example, using React Router: history.push(`/day-view/${moment(start).format('YYYY-MM-DD')}`);
    // Replace the routing logic with your application's routing mechanism
  };

  return (
    <>

      <div
        className="glass-body"
        style={{
          margin: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 0,
          backgroundColor: '#3887BE'
        }}
      >

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ul id="breadcrumb">
            <li><a href="/"><IoHomeOutline
              style={{ color: "white", fontSize: 20 }}
              
            /></a></li>
            <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span>Calendar</a></li>
            {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
            {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

          </ul>

        </div>

      </div>

      <div style={{ overflow: 'visible', display: 'flex', flexDirection: 'row', justifyContent:Op_per.get().CALENDAR.USER_DROPDOWN ? 'space-between' : 'end', alignItems: 'center', margin: '20px' }}>
        <div style={{ width: '300px', display: Op_per.get().CALENDAR.USER_DROPDOWN ? '' : 'none' }}>
          <Form.Group
            className="mb-3 d-flex w-100"
            style={{ gap: "15px" }}
            controlId="formBasicPassword"
          >
            <Form.Label className="mt-2">Users</Form.Label>
            <div className="selectdoctor" style={{ zIndex: '999' }}>
              <Select

                options={options}
                value={selectedUsers}
                onChange={handleSelectChange}
                styles={{

                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#2457C5' // Change color of the arrow here
                  })
                }}
              />
            </div>
          </Form.Group>

        </div>
        <Button style={{ backgroundColor: "#28B3D2", display: Op_per.get().CALENDAR.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={() => downloadCSV2(attendance)} >
        <RiFileExcel2Fill />
      </Button>
      </div>

      {/* <button style={{position:'absolute',right:40,top:87,backgroundColor:"#28B3D2", borderTopLeftRadius:20, borderBottomRightRadius:20,color:"#fff", borderColor:"none !important"}} className='btn  add-button' onClick={()=>downloadCSV(attendance)} disabled={selectedUserId == null}>Export</button> */}

      <div>
        <DragAndDropCalendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventStyleGetter}
          dayPropGetter={dayPropGetter}
          onDoubleClickEvent={handleEventClick}
          style={{ height: '80vh' }}
          events={event}
          onNavigate={onNavigate}
          selectable
          onSelectSlot={(slotInfo) => {
            if (slotInfo.action === 'click') {
              handleSelectSlot(slotInfo)
            } else {
              handleDoubleClick(slotInfo);
            }
          }}
          onSelecting={slotInfo => false} // Disable selecting a range of dates
          onView={handleNavigate}
          view={view}
          date={selectedDate}
          scrollToTime={new Date(0, 0, 0, 10, 0)}
          draggableAccessor="isDraggable"
          onEventResize={resizeEvent}
          onEventDrop={eventDrop}
          resizable={true}
          resizableAccessor={'isResizable'}
        />
      </div>

      <Modal show={showaddtask} onHide={() => setshowaddtask(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={task.task}
                onChange={(e) => { setTask({ ...task, task: e.target.value }) }}
              />
              <Form.Label >Assign To <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select

                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#2457C5' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project</Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    isClearable={true}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#2457C5' // Change color of the arrow here
                      })
                    }} />
                </div>
              </Form.Group>
              <Form.Label>Task Category <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    isClearable={true}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={task.description}
                onChange={(e) => { setTask({ ...task, description: e.target.value }) }} />

              <Form.Label>Start Date <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcDate.split(':')[0] + ":" + utcDate.split(':')[1]}
                onChange={(e) => { setUtcDate(dateToIsoStringWithoutChange(new Date(e.target.value))); setTask({ ...task, start: new Date(e.target.value).toISOString() }) }}
              />

              <Form.Label>End Date <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcEndDate.split(':')[0] + ":" + utcEndDate.split(':')[1]}
                onChange={(e) => { setUtcEndDate(dateToIsoStringWithoutChange(new Date(e.target.value))); setTask({ ...task, end: new Date(e.target.value).toISOString() }) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority <span style={{ color: 'red' }}>*</span> : </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value="low" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'low'} />
                    <span style={{ margin: '5px' }}>LOW</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value="medium" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'medium'} />
                    <span style={{ margin: '5px' }}>MEDIUM</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value="high" onChange={(e) => { setTask({ ...task, priority: e.target.value }) }} checked={task.priority == 'high'} />
                    <span style={{ margin: '5px' }}>HIGH</span>
                  </label>
                </div>
              </div>
            </div>

          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowaddtask(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlesubmit}
          >
            Create Task
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showedit} onHide={() => setshowedit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Title <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type="text"
                placeholder="Enter Task Title"
                value={currentmodaldata.task}
                onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, task: e.target.value }) }}
              />
              <Form.Label>Assign To </Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor" >
                  <Select
                    options={options}
                    value={selectedUsers}
                    onChange={handleSelectChange}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#2457C5' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Assign Project <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={projects}
                    value={selectedProject}
                    onChange={handleProjectChange}
                    isClearable={true}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#2457C5' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Task Category <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Group
                className="mb-3 d-flex w-100"
                style={{ gap: "15px" }}
                controlId="formBasicPassword"
              >
                <div className="selectdoctor">
                  <Select
                    options={category}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    isClearable={true}
                    styles={{

                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#2457C5' // Change color of the arrow here
                      })
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Label>Description <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                as='textarea'
                placeholder='Enter Task Description'
                value={currentmodaldata.description}
                onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, description: e.target.value }) }} />

              <Form.Label>Start Date <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcStartDate.split(':')[0] + ":" + utcStartDate.split(':')[1]}
                onChange={(e) => { setUtcStartDate(dateToIsoStringWithoutChange(new Date(e.target.value))); setCurrentModaldata({ ...currentmodaldata, start: new Date(e.target.value).toISOString() }) }}
              />
              <Form.Label>End Date <span style={{ color: 'red' }}>*</span></Form.Label>
              <Form.Control
                style={{
                  height: 46,

                  borderColor: '#28B3D2',
                  borderWidth: 2,
                  paddingHorizontal: 8,
                  marginBottom: 16,
                  borderTopLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                type='datetime-local'
                value={utcEndDate.split(':')[0] + ":" + utcEndDate.split(':')[1]}
                onChange={(e) => { setUtcEndDate(dateToIsoStringWithoutChange(new Date(e.target.value))); setCurrentModaldata({ ...currentmodaldata, end: new Date(e.target.value).toISOString() }) }} />
            </Form.Group>
            <div style={{ margin: '10px' }}>
              <div>
                <Form.Label>Priority : </Form.Label>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: 'rgb(59, 130, 246)', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="f-option" class="l-radio">
                    <input type="radio" id="f-option" name="selector" tabindex="1" value='low' onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, priority: e.target.value }) }} checked={currentmodaldata.priority == 'low'} />
                    <span style={{ margin: '5px' }}>LOW</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#28a745', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="s-option" class="l-radio">
                    <input type="radio" id="s-option" name="selector" tabindex="2" value='medium' onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, priority: e.target.value }) }} checked={currentmodaldata.priority == 'medium'} />
                    <span style={{ margin: '5px' }}>MEDIUM</span>
                  </label>
                </div>
                <div style={{ margin: '15px', color: 'white', display: 'inline', backgroundColor: '#ff0018', border: 'none', borderRadius: '8px', width: '60px', padding: '5px' }}>
                  <label for="t-option" class="l-radio">
                    <input type="radio" id="t-option" name="selector" tabindex="3" value='high' onChange={(e) => { setCurrentModaldata({ ...currentmodaldata, priority: e.target.value }) }} checked={currentmodaldata.priority == 'high'} />
                    <span style={{ margin: '5px' }}>HIGH</span>
                  </label>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setshowedit(false); setTask({ ...task, id: null }) }}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleeditsubmit}
          >
            Update Task
          </Button>
          {(() => {
            if (taskStatus === 'new') {
              return (
                <>
                  <Button
                    style={{ background: "#FDE767", color: '#000' }}
                    onClick={() => handlestatuschange(task.id, 'inprogress')}
                  >
                    In-Progress
                  </Button>
                  <Button
                    style={{ background: "#6DEE7A", color: '#000' }}
                    onClick={() => handlestatuschange(task.id, 'completed')}
                  >
                    Completed
                  </Button>
                </>
              )
            } else if (taskStatus === 'inprogress') {
              return (
                <>
                  <Button
                    style={{ background: "#6DEE7A", color: '#000' }}
                    onClick={() => handlestatuschange(task.id, 'completed')}
                  >
                    Completed
                  </Button>
                </>
              )
            }
            return null;
          })()}
        </Modal.Footer>


      </Modal>
    </>
  )
}

export default Attendance;