import Swal from "sweetalert2";
import Restapi from "../api/Restapi"
import { REPORTING_URL, constats } from "../constant"
import { isDayStarted } from "./Taskproxy";

export const GetCurrency = async () =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/currency',null);
}

export const GetExpenseTypeMaster = async () =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/expensetype',null);
}

export const GetExpenseByUser = async (userid) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/expensemanager/userid?userid='+userid,null);
}

export const GetExpenseByUserCopy = async (userid,page,size,status,manager,date) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/expensemanager/page/userid?userid=${userid}&page=${page}&size=${size}&status=${status}&manager=${manager}&date=${date}`,null);
}

export const GetExpenseByUserByMonth = async (month,year,userid) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/expensemanager/userid/month?month=${month}&year=${year}&userid=${userid}`,null);
}

export const CreateExpense = async (userid,expensetype,amount,description,createdby,approvedby,status,currency,date,attachment) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    if(isDayStarted()){
        const data = {
            'userid':userid,
            'expensetype':expensetype,
            'amount':amount,
            'description':description,
            'createdby':createdby,
            'approvedby':approvedby,
            'status':status,
            'currency':currency,
            'date':date,
            'attachment':attachment
        }
        return await Restapi('post',REPORTING_URL+'/apiv1/expensemanager',data);
    }else{
        Swal.fire({
            title: "Warning!",
            text: "First Start The Day !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
    }
}

export const EditExpense = async (id,userid,expensetype,amount,description,createdby,approvedby,status,currency,date,attachment) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        'userid':userid,
        'expensetype':expensetype,
        'amount':amount,
        'description':description,
        'createdby':createdby,
        'approvedby':approvedby,
        'status':status,
        'currency':currency,
        'date':date,
        'attachment':attachment
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/expensemanager/'+id,data);
}

export const DeleteExpense = async (id) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/expensemanager/'+id,null);
}

export const approveExpense = async (id,status,userid,approvedamount,remark) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/expensemanager/approve/'+id+`?status=${status}&userid=${userid}&approveamount=${approvedamount}&remark=${remark}`,null);
}

export const GetExpenseByStatus = async (status,userid) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/expensemanager/status/?status=${status}&userid=${userid}`,null);
}

export const GetExpenseByMonth = async (month,year,userid) =>{
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/expensemanager/getbymonth?month=${month}&year=${year}&userid=${userid}`,null);
}