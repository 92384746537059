import { useEffect, useState } from "react";
import { Accordion, Container, Table } from "react-bootstrap";
import { IoHomeOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { GetProjectDataByProjectId } from "../../utils/proxy/ProjectProxy";
import DataTable from "react-data-table-component";
import Helper from "../../utils/helperFunctions";
import Swal from "sweetalert2";

const ProjectWork = () => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [taskduration, setTaskDuration] = useState();
    const [totalTime, setTotalTime] = useState();
    const [taskstime,setTasksTime] = useState(0);
    const [usertaskcost,setUserTaskCost] = useState(0);

    useEffect(() => {
        async function getData() {
            if (state) {
                let d = await GetProjectDataByProjectId(state.id);
                if (d) {
                    setData(d);
                    let totalMilliseconds = 0;
                    let t = 0;
                    let ut = 0;
                    d?.users?.forEach(task => {
                        task?.tasks?.forEach(item => {

                            const startTime = new Date(item.start);
                            const endTime = new Date(item.end);

                            // Calculate the difference in milliseconds
                            const difference = endTime.getTime() - startTime.getTime();

                            // Add the difference to the total
                            totalMilliseconds += difference;

                        })

                        task?.tasks?.forEach(element => {
                            t = t + element.minutes;
                            ut = ut + element.usercost;
                        });
                        setTasksTime(t);
                        setUserTaskCost(ut)
                    })
                    const days = Math.floor(totalMilliseconds / (24 * 60 * 60 * 1000));
                    const hours = Math.floor((totalMilliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
                    const minutes = Math.floor((totalMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
                    const seconds = Math.floor((totalMilliseconds % (60 * 1000)) / 1000);

                    setTotalTime(`${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds`);
                }else{
                    Swal.fire({
                        title:'Error',
                        text:'Something Went Wrong',
                        icon:'error'
                    })
                }
            } else {
                navigate('/projects')
            }
        }

        getData();
    }, [])

    const CustomHeader = ({ children }) => (<div style={{ backgroundColor: 'blue', padding: '10px', color: 'white' }}>    {children}  </div>);

    const columns = [
        // {
        //   name: "Id",
        //   selector: (row) => row.id,
        //   sortable: true,
        // },
        {
            name: "Task",
            selector: (row) => row.task,
        },
        {
            name: "Start",
            selector: (row) => Helper.ConvertUtcToLocalTime(row.start).split(" ")[0] + ' ' + Helper.ConvertUtcToLocalTime(row.start).split(" ")[1].split(":")[0] + ":" + Helper.ConvertUtcToLocalTime(row.start).split(" ")[1].split(":")[1] + Helper.ConvertUtcToLocalTime(row.start).split(" ")[2],
            sortable: true
        }, {
            name: "End",
            selector: (row) => Helper.ConvertUtcToLocalTime(row.end).split(" ")[0] + ' ' + Helper.ConvertUtcToLocalTime(row.end).split(" ")[1].split(":")[0] + ":" + Helper.ConvertUtcToLocalTime(row.end).split(" ")[1].split(":")[1] + Helper.ConvertUtcToLocalTime(row.end).split(" ")[2],
            sortable: true
        },
        {
            name: "Status",
            selector: (row) => row.status.toUpperCase(),
        },
    ];

    function calculateTotalTime(dataList) {
        let totalMilliseconds = 0;

        dataList.forEach(item => {
            const startTime = new Date(item.start);
            const endTime = new Date(item.end);

            // Calculate the difference in milliseconds
            const difference = endTime.getTime() - startTime.getTime();

            // Add the difference to the total
            totalMilliseconds += difference;
        });

        // Convert the total milliseconds to hours, minutes, and seconds
        const days = Math.floor(totalMilliseconds / (24 * 60 * 60 * 1000));
        const hours = Math.floor((totalMilliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((totalMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((totalMilliseconds % (60 * 1000)) / 1000);

        setTaskDuration(`${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds`);

    }

    function formatRupees(amount) {
        const formatter = new Intl.NumberFormat('en-IN', {
            // style: 'currency',
            // currency: 'INR',
            maximumFractionDigits: 2
        });
        return formatter.format(amount);
    }

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}

                        /></a></li>
                        <li><a href="/projects"><span class="icon icon-beaker"> </span>Projects Work</a></li>
                        {/* <li><a href="/user-doctor-master"><span class="icon icon-beaker"> </span> User Doctor Master</a></li> */}
                        {/* <li><a href="#"><span class="icon icon-double-angle-right"></span>Doctor</a></li> */}

                    </ul>

                </div>
            </div>
            <div className="glass-body" style={{ margin: 10 }}>
                <div>
                    <Table bordered>
                        <thead style={{background:'#E5E1DA',fontWeight:'bold'}}>
                            <tr>
                                <td>Project Name</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Cost</td>
                                <td>Total Time On Project</td>
                                <td>Total Cost</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data.projectname}</td>
                                <td>{data?.startdate?.split('T')[0]}</td>
                                <td>{data?.enddate?.split('T')[0]}</td>
                                <td>{formatRupees(data.cost)}</td>
                                <td>{totalTime && totalTime}</td>
                                <td>{formatRupees(Math.round(usertaskcost))} /-</td>
                            </tr>
                        </tbody>
                    </Table>

                    <Accordion>
                        {data?.users?.map((user, index) => (
                            <Accordion.Item onClick={e => { if (e.target.classList.contains('accordion-button') && e.target.classList.contains('collapsed')) { calculateTotalTime(user.tasks) } }} eventKey={index}>
                                <Accordion.Header>{user.firstname+" "+user.lastname} &#x28; {user?.tasks.length} tasks &#x29;</Accordion.Header>
                                <Accordion.Body>
                                    <div key={index}>
                                        <samp>{taskduration && taskduration}</samp>
                                        <DataTable
                                            columns={columns}
                                            data={user.tasks}
                                            customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                                            CustomHeader={<CustomHeader />}
                                            pagination
                                            dense
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </Container>
    )
}

export default ProjectWork;