import { UserMoods } from "../../utils/constant";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Helper from "../../utils/helperFunctions";
import Swal from "sweetalert2";
import { GetMoodsCountByMonth } from "../../utils/proxy/DayInOutProxy";

function MoodChart({ setMoodMonth, userid }) {

    const months = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
    }

    const [currdate, setCurrdate] = useState(new Date());
    const [data, setData] = useState([]);

    setMoodMonth(months[currdate.getMonth() + 1] + " " + currdate.getFullYear());

    useEffect(() => {
        const getMoods = async () => {
            let d = await GetMoodsCountByMonth(currdate.getFullYear() + "-" + Helper.addLeadingZero(currdate.getMonth() + 1),userid);
            if (d) {
                setData(d);
            } else {
                Swal.fire({
                    title: 'Error',
                    text: 'Something Went Wrong',
                    icon: 'error'
                })
            }
        }
        getMoods();
    }, [currdate,userid])

    function subtractMonths(date, months) {
        const d = new Date(date);
        d.setMonth(d.getMonth() - months);

        // Handle edge case where subtracting months might affect the day
        if (d.getDate() !== date.getDate()) {
            d.setDate(0);
        }

        setCurrdate(d);
    }

    function addMonths(date, months) {
        const d = new Date(date);
        d.setMonth(d.getMonth() + months);

        // Handle edge case where adding months might affect the day
        if (d.getDate() !== date.getDate()) {
            d.setDate(0);
        }

        setCurrdate(d);
    }

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center gap-2 overflow-hidden">
                <GrFormPrevious size={15} onClick={() => subtractMonths(currdate, 1)} style={{ flexGrow: 1 }} />
                <div>
                    <table className="overflow-hidden" size="sm">
                        <tbody>
                            <tr>
                                <td className="m-3"></td>
                                {data.map(mood => (
                                    <td style={{ padding: 5 }}>
                                        <div className='d-flex flex-column align-items-center'>
                                            <div className="waves-effect" title={mood.mood} style={{ fontSize: 25 }}>{mood.mood == "Bad" ? UserMoods[4].mood : '' || mood.mood == "Poor" ? UserMoods[3].mood : '' || mood.mood == "Neutral" ? UserMoods[2].mood : '' || mood.mood == "Good" ? UserMoods[1].mood : '' || mood.mood == "Perfect" ? UserMoods[0].mood : ''}</div>
                                            {/* <span>{mood.value}</span> */}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Start Day</td>
                                {data.map((mood, index) => (
                                    <td style={{ padding: 5 }}>
                                        <div className='border d-flex flex-column align-items-center'>
                                            <div style={{ fontSize: 20 }}>{mood.startdaycount}</div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>End Day</td>
                                {data.map((mood, index) => (
                                    <td style={{ padding: 5 }}>
                                        <div className='border d-flex flex-column align-items-center'>
                                            <div style={{ fontSize: 20 }}>{mood.enddaycount}</div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <MdNavigateNext size={15} onClick={() => addMonths(currdate, 1)} style={{ flexGrow: 1 }} />
            </div>
        </div>
    )
}

export default MoodChart;