import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap"
import { IoHomeOutline } from "react-icons/io5"
import Select from "react-select";
import { AllUsers, GetAllManagerUsers } from "../../utils/proxy/Authproxy";
import DataTable from "react-data-table-component";
import { GetTpAchievements } from "../../utils/proxy/ChartsProxy";
import { RiFileExcel2Fill } from "react-icons/ri";
import { Parser } from "@json2csv/plainjs";
import { GetDoctorDpDetails } from "../../utils/proxy/Doctorproxy";
import { GetHospitalDpDetails } from "../../utils/proxy/Hospitalsproxy";
import { GetClinicDpDetails } from "../../utils/proxy/Clinicproxy";
import { GetChemistDpDetails } from "../../utils/proxy/Chemistproxy";
import { GetStockistDpDetails } from "../../utils/proxy/Stockistproxy";
import { Op_per } from "../../utils/Permissions";

export const TpAchievements = () => {

    const months = [
        { label: "January", value: "01" },
        { label: "February", value: "02" },
        { label: "March", value: "03" },
        { label: "April", value: "04" },
        { label: "May", value: "05" },
        { label: "June", value: "06" },
        { label: "July", value: "07" },
        { label: "August", value: "08" },
        { label: "September", value: "09" },
        { label: "October", value: "10" },
        { label: "November", value: "11" },
        { label: "December", value: "12" },
    ];

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push({ value: i.toString(), label: i.toString() });
    }

    const [data, setData] = useState([]);
    const [userid, setUserId] = useState(localStorage.getItem('role') === 'User' ? localStorage.getItem('uuid') : null);
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]); // Set the default month to '01'
    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [reload, setReload] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dpData, setDpData] = useState([]);
    const [attendanceDetails, setAttendanceDetails] = useState({fieldwork:0,holiday:0,weeklyoff:0,leaves:0,totaldates:0});
    const [callDetails, setCallDetails] = useState({totalDoctorMet:0,totalHospitalMet:0,totalChemistMet:0,totalClinicMet:0,totalStockistMet:0});

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallusers = async () => {
            if (localStorage.getItem('role') === 'Admin') {
                let allusers = await AllUsers();
                setUsers(allusers);
            } else if (localStorage.getItem('role') === 'Manager') {
                let allusers = await GetAllManagerUsers(localStorage.getItem('uuid'));
                setUsers(allusers);
            }
        };
        getallusers();
    }, []);

    useEffect(() => {
        // Fetch data from an API when the component mounts
        const getallteritories = async (userid, selectedMonth, selectedYear) => {
            let userteritories = await GetTpAchievements(
                userid,
                selectedMonth,
                selectedYear
            );

            if (userteritories) {
                setData(userteritories);
                setAttendanceDetails({fieldwork:0,holiday:0,weeklyoff:0,leaves:0,totaldates:0});
                setCallDetails({totalDoctorMet:0,totalHospitalMet:0,totalChemistMet:0,totalClinicMet:0,totalStockistMet:0});
                setAttendanceDetails(prevAttendance => ({
                    ...prevAttendance,
                    totaldates: userteritories.length
                  }));
                userteritories.map(data=>{
                    if (data.worked === 'Field Work') {
                        setAttendanceDetails(prevAttendance => ({
                            ...prevAttendance,
                            fieldwork: prevAttendance.fieldwork + 1
                          }));
                    }
                    
                    if(data.aspertp === 'Leave'){
                        setAttendanceDetails(prevAttendance => ({
                            ...prevAttendance,
                            leaves: prevAttendance.leaves + 1
                          }));
                    }

                    if(data.worktype === 'Weekly Off'){
                        setAttendanceDetails(prevAttendance => ({
                            ...prevAttendance,
                            weeklyoff: prevAttendance.weeklyoff + 1
                          }));
                    }else if(data.worktype !== 'working'){
                        setAttendanceDetails(prevAttendance => ({
                            ...prevAttendance,
                            holiday: prevAttendance.holiday + 1
                          }));
                    }

                    setCallDetails(detail =>({
                        ...detail,
                        totalClinicMet:detail.totalClinicMet+data.clinics_met,
                        totalHospitalMet:detail.totalHospitalMet+data.hospitals_met,
                        totalDoctorMet:detail.totalDoctorMet+data.doctors_met,
                        totalStockistMet:detail.totalStockistMet+data.stockists_met,
                        totalChemistMet:detail.totalChemistMet+data.chemist_met,
                    }))
                })
            } else {
                alert("Error", "Issue While Fetching Tour Plan");
            }
        };
        if (userid && selectedMonth.value && selectedYear.value) {
            getallteritories(userid, selectedMonth.value, selectedYear.value);
        }
        setReload(false);
    }, [reload, userid, selectedMonth, selectedYear]);

    const handleSelectChange = (selected) => {
        setUserId(selected.value);
        setSelectedUser(selected)
    };

    const handleSelectChangeMOnth = (selected) => {
        setSelectedMonth(selected);
    };

    const handleSelectChangeYear = (selected) => {

        setSelectedYear(selected);
    };

    const options = [];

    users.map((user) => {
        options.push({
            value: user.userid,
            label: user.username + "(" + user.firstname + " " + user.lastname + ")",
        });
    });

    const handleDpClick = async (date, userid, type, dpdata) => {
        if (dpdata != 0) {
            if (type === 'doctor') {
                let d = await GetDoctorDpDetails(userid, date);
                setDpData(d);
                setShowModal(true);
            } else if (type === 'hospital') {
                let d = await GetHospitalDpDetails(userid, date);
                setDpData(d);
                setShowModal(true);
            } else if (type === 'chemist') {
                let d = await GetChemistDpDetails(userid, date);
                setDpData(d);
                setShowModal(true);
            } else if (type === 'clinic') {
                let d = await GetClinicDpDetails(userid, date);
                setDpData(d);
                setShowModal(true);
            } else if (type === 'stockist') {
                let d = await GetStockistDpDetails(userid, date);
                setDpData(d);
                setShowModal(true);
            }
        }
    }

    const columns = [
        {
            name: "Date",
            selector: (row) => row.date,
            margin: 30,
            width: "10%",
        },
        {
            name: "Work Type",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.worktype === "" || row.worktype === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.worktype === "" || row.worktype === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.worktype === "" || row.worktype === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.worktype ? row.worktype : "N/A"}</div>,
            width: "12%",

        },
        {
            name: "As Per Tp",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.aspertp == "" ? "N/A" : row.aspertp + " " + row.aspertp === "" || row.aspertp == "" ? "N/A" : row.aspertp + " " + row.aspertp === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.aspertp == "" ? "N/A" : row.aspertp + " " + row.aspertp === "" || row.aspertp == "" ? "N/A" : row.aspertp + " " + row.aspertp === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.aspertp == "" ? "N/A" : row.aspertp + " " + row.aspertp === "" || row.aspertp == "" ? "N/A" : row.aspertp + " " + row.aspertp === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.aspertp == "" ? "N/A" : row.aspertp}</div>,
            width: "12%",
        },
        {
            name: "Worked",
            selector: (row) => <div style={{ whiteSpace: 'normal', backgroundColor: row.worked == "" ? "N/A" : row.worked + " " + row.worked === "" || row.worked == "" ? "N/A" : row.worked + " " + row.worked === null ? "red" : "#D9E1FF", borderRadius: "10px", border: row.worked == "" ? "N/A" : row.worked + " " + row.worked === "" || row.worked == "" ? "N/A" : row.worked + " " + row.worked === null ? "2px solid red" : "2px solid #28B3D2", padding: "3px", display: "flex", flexDirection: "row", color: row.worked == "" ? "N/A" : row.worked + " " + row.worked === "" || row.worked == "" ? "N/A" : row.worked + " " + row.worked === null ? "#fff" : "#2457C5", margin: "3px", }}>{row.worked == "" ? "N/A" : row.worked}</div>,
            width: "12%",
        },
        {
            name: <p>Doctors Met</p>,
            selector: (row) => <span className="cursor" onClick={() => handleDpClick(row.date, userid, 'doctor', row.doctors_met)}>{row.doctors_met}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.unplanned_doctor=== "" || row.unplanned_doctor=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.unplanned_doctor? row.unplanned_doctor: "N/A"}</div> ,
            width: "5%",
        },
        {
            name: <p>Clinics Met</p>,
            selector: (row) => <span className="cursor" onClick={() => handleDpClick(row.date, userid, 'clinic', row.clinics_met)}>{row.clinics_met}</span>,

            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_clinic=== "" || row.planned_clinic=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_clinic=== "" || row.planned_clinic=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_clinic=== "" || row.planned_clinic=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_clinic? row.planned_clinic: "N/A"}</div>,
            width: "5%",
        },
        {
            name: <p>Chemist Met</p>,
            selector: (row) => <span className="cursor" onClick={() => handleDpClick(row.date, userid, 'chemist', row.chemist_met)}>{row.chemist_met}</span>,
            // <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_chemist=== "" || row.planned_chemist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_chemist=== "" || row.planned_chemist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_chemist=== "" || row.planned_chemist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_chemist? row.planned_chemist: "N/A"}</div> ,
            width: "5%",
        },
        {
            name: <p>Stockist Met</p>,
            selector: (row) => <span className="cursor" onClick={() => handleDpClick(row.date, userid, 'stockist', row.stockists_met)}>{row.stockists_met}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_stockist=== "" || row.planned_stockist=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_stockist=== "" || row.planned_stockist=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_stockist=== "" || row.planned_stockist=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_stockist? row.planned_stockist: "N/A"}</div>  ,
            width: "5%",
        },
        {
            name: <p>Hospital Met</p>,
            selector: (row) => <span className="cursor" onClick={() => handleDpClick(row.date, userid, 'hospital', row.hospitals_met)}>{row.hospitals_met}</span>,
            //  <div style={{ whiteSpace: 'normal',backgroundColor: row.planned_hospital=== "" || row.planned_hospital=== null ? "red":"#D9E1FF", borderRadius:"10px", border: row.planned_hospital=== "" || row.planned_hospital=== null ? "2px solid red": "2px solid #28B3D2",padding:"3px", display:"flex", flexDirection:"row",color:row.planned_hospital=== "" || row.planned_hospital=== null ? "#fff": "#2457C5", margin:"3px", }}>{row.planned_hospital? row.planned_hospital: "N/A"}</div> ,
            width: "5%",
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.aspertp == "Holiday",
            style: {
                backgroundColor: 'rgb(245, 185, 0,0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.aspertp == "Not Submitted",
            style: {
                backgroundColor: '#ff0000ab',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ]

    async function downloadCSV(plans, attendanceDetails, callDetails) {
        let csv;
        try {
            const opts = {
                fields: [
                    { label: "Date", value: "date" },
                    { label: "Work Type", value: "worktype" },
                    { label: "As Per Tp", value: "aspertp" },
                    { label: "Worked", value: "worked" },
                    { label: "Doctors Met", value: "doctors_met" },
                    { label: "Clinics Met", value: "clinics_met" },
                    { label: "Chemists Met", value: "chemist_met" },
                    { label: "Stockists Met", value: "stockists_met" },
                    { label: "Hospitals Met", value: "hospitals_met" },
                ],
            };
            const parser = new Parser(opts);
            csv = parser.parse(plans);
        } catch (err) {
            console.error(err);
        }

        const link = document.createElement("a");

        if (csv == null) return;

        const attendanceDetailsCsv = `
            Attendance Details
            Field Work: ${attendanceDetails.fieldwork}
            Holiday: ${attendanceDetails.holiday}
            Weekly Off: ${attendanceDetails.weeklyoff}
            Leaves: ${attendanceDetails.leaves}
            Total Dates: ${attendanceDetails.totaldates}
        `;

        const callDetailCsv = `
            Call Details
            Doctor Met: ${callDetails.totalDoctorMet}
            Doctor Call Average: ${attendanceDetails.fieldwork!= 0 ? (callDetails.totalDoctorMet / attendanceDetails.fieldwork).toFixed(2) : 0}
            Chemist Met: ${callDetails.totalChemistMet}
            Chemist Call Average: ${attendanceDetails.fieldwork!= 0 ? (callDetails.totalChemistMet / attendanceDetails.fieldwork).toFixed(2) : 0}
            Hospital Met: ${callDetails.totalHospitalMet}
            Hospital Call Average: ${attendanceDetails.fieldwork!= 0 ? (callDetails.totalHospitalMet / attendanceDetails.fieldwork).toFixed(2) : 0}
            Stockist Met: ${callDetails.totalStockistMet}
            Stockist Call Average: ${attendanceDetails.fieldwork!= 0 ? (callDetails.totalStockistMet / attendanceDetails.fieldwork).toFixed(2) : 0}
            Clinic Met: ${callDetails.totalClinicMet}
            Clinic Call Average: ${attendanceDetails.fieldwork!= 0 ? (callDetails.totalClinicMet / attendanceDetails.fieldwork).toFixed(2) : 0}
        `;

        const month = months.find(month => month.value === selectedMonth.value);

        const filename = `TpVsAchievement-${month.label}/${selectedYear.value}.csv`;

        csv = `Tp Vs Achievement Report for ${month.label} ${selectedYear.value} of ${selectedUser.label} \n\n\n${csv}`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        csv = `${csv} \n${attendanceDetailsCsv}\n${callDetailCsv}`;

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <Button style={{ position: 'absolute', right: 40, backgroundColor: "#28B3D2 ",display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.ALLOW_EXPORT_XLSX ? '' : 'none' }} onClick={(e) => onExport(e.target.value)} disabled={!(userid && selectedMonth.value && selectedYear.value)}><RiFileExcel2Fill /></Button>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(data,attendanceDetails,callDetails)} />,
        [data]
    );

    return (
        <Container fluid style={{ minHeight: "80vh" }}>
            <div
                className="glass-body"
                style={{
                    margin: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 0,
                    backgroundColor: '#3887BE'
                }}
            >

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ul id="breadcrumb">
                        <li><a href="/"><IoHomeOutline
                            style={{ color: "white", fontSize: 20 }}
                            
                        /></a></li>
                        <li><a href="/tp-achievement"><span class="icon icon-beaker"> </span>Reports</a></li>

                        <li><a href="#"><span class="icon icon-double-angle-right"></span>Tp Vs Achievements</a></li>

                    </ul>

                </div>

            </div>

            <div className="glass-body" style={{ margin: 10 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                    }}
                >
                    <div style={{ width: "320px", display:Op_per.get().REPORTS_TP_VS_ACHIEVEMENT.USER_DROPDOWN ? '' : 'none' }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Users</Form.Label>
                            <div className="w-75">
                                <Select
                                    className="selectdoctor"
                                    options={options}
                                    value={selectedUser}
                                    onChange={handleSelectChange}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Month</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={months}
                                    value={selectedMonth}
                                    onChange={handleSelectChangeMOnth}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div style={{ width: "450px" }}>
                        <Form.Group
                            className="mb-3 d-flex w-100"
                            style={{ gap: "15px" }}
                            controlId="formBasicPassword"
                        >
                            <Form.Label className="mt-2">Select Year</Form.Label>
                            <div className="w-50">
                                <Select
                                    className="selectdoctor"
                                    options={years}
                                    value={selectedYear}
                                    onChange={handleSelectChangeYear}
                                    styles={{

                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            color: '#2457C5' // Change color of the arrow here
                                        })
                                    }}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="pt-3">
                    <DataTable
                        columns={columns}
                        data={data}
                        conditionalRowStyles={conditionalRowStyles}
                        actions={actionsMemo}
                        pagination
                        customStyles={{ headRow: { style: { backgroundColor: '#E5E1DA', padding: 7 }, } }}
                        style={{ margin: '10px', padding: 10 }}
                        dense
                    />
                </div>

                {data.length > 0 ? <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'flex-start'}}>
                <table className="table" style={{margin:5}} border={1}>
                    <thead style={{fontWeight:'bold'}}>
                        <tr>
                            <td>Attendance Details</td>
                            <td>Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Field Work</td>
                            <td>{attendanceDetails.fieldwork}</td>
                        </tr>
                        <tr>
                            <td>Holiday</td>
                            <td>{attendanceDetails.holiday}</td>
                        </tr>
                        <tr>
                            <td>Weekly off</td>
                            <td>{attendanceDetails.weeklyoff}</td>
                        </tr>
                        <tr>
                            <td>Leaves</td>
                            <td>{attendanceDetails.leaves}</td>
                        </tr>
                        <tr>
                            <td>Total No Of Dates</td>
                            <td>{attendanceDetails.totaldates}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table" style={{margin:5}} border={1}>
                    <thead style={{fontWeight:'bold'}}>
                        <tr>
                            <td>Call Details</td>
                            <td>Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Total Doctors Met</td>
                            <td>{callDetails.totalDoctorMet}</td>
                        </tr>
                        <tr>
                            <td>Call Average Of Doctor</td>
                            <td>{attendanceDetails.fieldwork!= 0 ? (callDetails.totalDoctorMet / attendanceDetails.fieldwork).toFixed(2) : 0}</td>
                        </tr>
                        <tr>
                            <td>Total Chemist Met</td>
                            <td>{callDetails.totalChemistMet}</td>
                        </tr>
                        <tr>
                            <td>Call Average Of Chemist</td>
                            <td>{attendanceDetails.fieldwork!= 0 ? (callDetails.totalChemistMet / attendanceDetails.fieldwork).toFixed(2) : 0}</td>
                        </tr>
                        <tr>
                            <td>Total Hospitals Met</td>
                            <td>{callDetails.totalHospitalMet}</td>
                        </tr>
                        <tr>
                            <td>Call Average Of Hospital</td>
                            <td>{attendanceDetails.fieldwork!= 0 ? (callDetails.totalHospitalMet / attendanceDetails.fieldwork).toFixed(2) : 0}</td>
                        </tr>
                        <tr>
                            <td>Total Stockist Met</td>
                            <td>{callDetails.totalStockistMet}</td>
                        </tr>
                        <tr>
                            <td>Call Average Of Stockist</td>
                            <td>{attendanceDetails.fieldwork!= 0 ? (callDetails.totalStockistMet / attendanceDetails.fieldwork).toFixed(2) : 0}</td>
                        </tr>
                        <tr>
                            <td>Total Clinics Met</td>
                            <td>{callDetails.totalClinicMet}</td>
                        </tr>
                        <tr>
                            <td>Call Average Of Clinic</td>
                            <td>{attendanceDetails.fieldwork!= 0 ? (callDetails.totalClinicMet / attendanceDetails.fieldwork).toFixed(2) : 0}</td>
                        </tr>
                    </tbody>
                </table>
                </div> : null}

            </div>
            

            <Modal size="lg" style={{height:'80%',overflow:'auto'}} show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <div style={{height:'390px',scrollbarWidth:'thin'}} className="overflow-auto">
                        {dpData.length > 0 && <table className="table">
                            <thead>
                                <tr>
                                    {Object.entries(dpData[0]).map(([key,value]) => (
                                        <th style={{border:'1px solid black'}}>{key}</th>
                                        ))}
                                </tr>
                            </thead>
                                        {dpData.map((obj)=>(
                            <tbody>
                                <tr>
                                    {Object.values(obj).map((value, index) => (
                                        <td style={{border:'1px solid black'}} key={index}>{value ?? '--'}</td>
                                    ))}
                                </tr>
                            </tbody>
                    ))}
                        </table>}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}