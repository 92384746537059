import { data } from "jquery";
import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"

// TDS Tax
export const getAlltdsTaxes = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tdstax',null);
}

export const createtdstaxe = async (taxname,rate,sectionid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "taxname":taxname,
        "rate":rate,
        "sectionid":sectionid
    })

    return await Restapi('post',REPORTING_URL+'/apiv1/tdstax',data);
}


export const updatetdstaxe = async (id,taxname,rate,sectionid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "taxname":taxname,
        "rate":rate,
        "sectionid":sectionid
    })

    return await Restapi('put',REPORTING_URL+'/apiv1/tdstax/'+id,data);
}


export const deletetdstax = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/tdstax/'+id,null);
}


// TCS Tax
export const getAlltcsTaxes = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tcstax',null);
}

export const createtcstaxe = async (taxname,rate,sectionid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "taxname":taxname,
        "rate":rate,
        "sectionid":sectionid
    })

    return await Restapi('post',REPORTING_URL+'/apiv1/tcstax',data);
}


export const updatetcstaxe = async (id,taxname,rate,sectionid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "taxname":taxname,
        "rate":rate,
        "sectionid":sectionid
    })

    return await Restapi('put',REPORTING_URL+'/apiv1/tcstax/'+id,data);
}


export const deletetcstax = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/tcstax/'+id,null);
}


// TDS Section

export const getAllTdsSection = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tdssection',null);
}

export const getTdsSectionById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tdssection/'+id,null);
}

export const createtdsSection = async (section,desc) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "section":section,
        "desc":desc
    })

    return await Restapi('post',REPORTING_URL+'/apiv1/tdssection',data);
}

export const updatetdsSection = async (id,section,desc) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "section":section,
        "desc":desc
    })

    return await Restapi('put',REPORTING_URL+'/apiv1/tdssection/'+id,data);
}


export const deletetdsSection = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/tdssection/'+id,null);
}


// TCS Section

export const getAllTcsSection = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tcssection',null);
}

export const getTcsSectionById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/tcssection/'+id,null);
}

export const createtcsSection = async (section,desc) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "section":section,
        "desc":desc
    })

    return await Restapi('post',REPORTING_URL+'/apiv1/tcssection',data);
}

export const updatetcsSection = async (id,section,desc) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    let data = JSON.stringify({
        "section":section,
        "desc":desc
    })

    return await Restapi('put',REPORTING_URL+'/apiv1/tcssection/'+id,data);
}


export const deletetcsSection = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();

    return await Restapi('delete',REPORTING_URL+'/apiv1/tcssection/'+id,null);
}



// Taxrules

export const getAllTaxRules = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/taxrules',null);
}

export const CreateTaxRules = async (name,level,taxRate,selectedcountries,selectedstates) => {
    const data = {
        name,
        level,
        taxrate: taxRate,
        countryIds: selectedcountries,
        stateIds: selectedstates
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/taxrules',data);
}


export const UpdateTaxRules = async (id,name,level,taxRate,selectedcountries,selectedstates) => {
    const data = {
        name,
        level,
        taxrate: taxRate,
        countryIds: selectedcountries,
        stateIds: selectedstates
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/taxrules/'+id,data);
}

export const deleteTaxRules = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/taxrules/'+id,null);
}

export const GetTaxRulesByState = async (state) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/taxrules/state/'+state,null);
}

//Tax Configuration
export const CreateTaxConfiguration = async (taxSupport ,taxNumber, taxType, customNumber, customerFormat, nextInvoiceNumber,autoResetNumbering,companyname,address,email,telephone,bankdetail) => {
    const data = {
       taxsupport: taxSupport,
       taxnumber: taxNumber,
       taxtype: taxType,
       custominvoicenumber: customNumber,
       customerinvoicenumberformat: customerFormat,
       nextinvoicenumber: nextInvoiceNumber,
       autoresetnumbering: autoResetNumbering,
       companyname,
       address,
       email,
       telephone,
       bankdetail
    }
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/taxconfiguration',data);
}
export const GetAllTaxConfiguration = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/taxconfiguration',null);
}


