import Swal from "sweetalert2";
import Restapi from "../api/Restapi";
import {REPORTING_URL, constats} from "../constant"

export const GetAllTask = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task',null);
}

export const GetAllTaskByUser = async (userid,page,size,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task/page?userid='+userid+`&page=${page}&size=${size}&status=${status}`,null);
}

export const GetAllTaskByUserCopy = async (userid,page,size,status,manager,date) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task/page/copy?userid='+userid+`&page=${page}&size=${size}&status=${status}&manager=${manager}&date=${date}`,null);
}

export const GetAllTaskByLead = async (leadid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task?leadid='+leadid,null);
}

export const GetTaskById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task/'+id,null);
}

export const GetTaskByMonth = async (month,year,userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+`/apiv1/task/getbymonth?month=${month}&year=${year}&userid=${userid}`,null);
}

export const GetAllPendingTask = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task/user/pending',null);
}

export const GetAllPendingTaskByuserid = async (userid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task/user/pending?userid='+userid,null);
}

export const CreateTask = async (task) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    if(isDayStarted()){
        return await Restapi('post',REPORTING_URL+'/apiv1/task',task);
    }else{
        Swal.fire({
            title: "Warning!",
            text: "First Start The Day !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
    }
}

export const GetAllTaskByStatus = async (status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task?status='+status,null);
}

export const GetTaskUnderManagerUsers = async (managerid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/task/manager?managerid='+managerid,null);
}

export const DeleteTaskById = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/task/'+id,null);
}

export const UpdateTaskById = async (id,task) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/task/'+id,task);
}

export const ChangeTaskStatus = async (id,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/task/'+id+`?status=${status}`,null);
}

export const GetAllTaskCategory = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/taskcategory',null);
}

export const CreateTaskCategory = async (name,worth,cost,currency,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        name:name,
        worth:worth,
        cost:cost,
        currency:currency,
        status:status
    }
    return await Restapi('post',REPORTING_URL+'/apiv1/taskcategory',data);
}

export const UpdateTaskCategory = async (id,name,worth,cost,currency,status) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    const data = {
        name:name,
        worth:worth,
        cost:cost,
        currency:currency,
        status:status
    }
    return await Restapi('put',REPORTING_URL+'/apiv1/taskcategory/'+id,data);
}

export const DeleteTaskCategory = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/taskcategory/'+id,null);
}

export const isDayStarted = () =>{
    if(localStorage.getItem('startdayid') != null && localStorage.getItem('startday') != null ){
        return true;
    }

    return false;
}

export const UpdateTaskTimingById = async (id,start,end,operation) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/task/changetime/'+id+`?start=${start}&end=${end}&operation=${operation}`,null);
}