import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Table } from 'react-bootstrap';
import { useCallback } from 'react';


const PdfGenerator = (({data, company}) => {

    function calculateDiscount(originalPrice, discountPercentage, operation) {

        const discountAmount = (originalPrice * discountPercentage) / 100;
        let finalPrice = 0;

        if (operation == 'add') {
            finalPrice = originalPrice + discountAmount;
        } else {
            finalPrice = originalPrice - discountAmount;
        }
        return finalPrice;
    }

    const totalCalculate = useCallback((data) =>{
        let total = calculateDiscount(data.products.reduce((sum, item) => 
        item.taxed ? sum + parseFloat(item.taxprice || 0) : sum + parseFloat(item.total || 0), 0)
        ,data.products.reduce((sum, item) => sum + parseFloat(item.discount || 0), 0))

        let temp = calculateDiscount(total,((data.tcsid || data.tdsid) ? data.tdsid ? data.tdsrate : data.tcsrate : 0 ),'minus');

        return temp.toFixed(2);
    },[data])

  return (
    <div>
      <div style={{ padding: '20px', backgroundColor: '#fff', margin:'20px' }}>
        <div style={{margin:'20px 20px 40px 20px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <img src='images/nav2.png' width={270} height={100} alt="Team Insights" />
                </div>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div>
                        <h2>Quotation</h2>
                        <div>Date Created : {new Date(data.createdat).toLocaleDateString('en-IN')}</div>
                        <div>Valid Till : {new Date(data.duedate).toLocaleDateString('en-IN')}</div>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div style={{margin:'20px 20px 40px 20px'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{maxWidth:320}}>
                    <div>To :</div>
                    <div style={{fontWeight:'bold'}}>{`${data.salutation} ${data.firstname} ${data.lastname!=null ? data.lastname : ''} , ${data.title}`} </div>
                    <div>{data.company} </div>
                    <div>{`${data.street} ${data.city} ${data.state} ${data.country}`} </div>
                    <div>{data.taxid && data.taxid} </div>
                </div>
                <div style={{maxWidth:300}}>
                    <div>From :</div>
                    <div style={{fontWeight:'bold'}}>{company.companyname}  </div>
                    <div>{company.address}  </div>
                    <div>{company.email} </div>
                    <div>{company.telephone} </div>
                    <div>{company.taxnumber}</div>
                </div>
            </div>
        </div>

        <div style={{margin:'20px 20px 40px 20px'}}>
        {/* <div>
            <h3>Products : </h3>
        </div> */}
        <table style={{width:'100%',borderCollapse:'collapse',margin:'20px 0',fontSize:'16px',textAlign:'left'}}>
                <thead>
                    <tr>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Product Name</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Qty</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Price</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Discount</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Total</th>
                        <th style={{padding:'12px',borderBottom:'1px solid #ddd',background:'#f2f2f2',fontWeight:'bold'}}>Tax Price</th>
                    </tr>
                </thead>
                <tbody>
                {data.products.map((d, index) => (
                                <tr key={index}>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.productname}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.quantity}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.price}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.discount}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.total}</td>
                                    <td style={{padding:'12px',borderBottom:'1px solid #ddd',background:index%2==0 ? '#f9f9f9':'#fff'}}>{d.taxprice}</td>
                                </tr>
                            ))}
                <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{padding:'12px',borderBottom:'1px solid #ddd'}} colSpan="2">{(data.tdsid || data.tcsid) ? (data.tcsid ? 'TCS -' : 'TDS -') : ''} {(data.tdsid || data.tcsid) ? (data.tcsid ? data.tcsrate +' %' : data.tdsrate+' %') : ''}</td>
                            </tr>
                <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{padding:'12px',borderBottom:'1px solid #ddd'}} colSpan="2">Grand Total - {totalCalculate(data)}</td>
                            </tr>

                </tbody>
            </table>
        </div>

        <div style={{margin:'20px 20px 40px 20px'}}>
            <div>
                Notes: In case of any query feel free to revert back.
            </div>
        </div>

        <div style={{margin:'20px 20px 40px 20px'}}>
            {company.bankdetail && <><div>Bank Details:</div>
            <div>{company.bankdetail}</div></>}
        </div>
      </div>
    </div>
  );
});

export default PdfGenerator;
