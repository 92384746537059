import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import Placeholder from 'react-bootstrap/Placeholder';

import Dashboard from '../Dashboard';
import Countrymaster from '../Country/Countrymaster';
import Login from '../Auth/Login';
import Statemaster from '../Country/Statemaster';
import Citymaster from '../Country/Citymaster';
import Areamaster from '../Country/Areamaster';
import Userlocation from '../User/Userlocation';
import Doctormaster from '../Doctors/Doctormaster';
import Doctordegree from '../Doctors/Doctordegree';
import Doctorspecial from '../Doctors/Doctorspecial';
import Clinicmaster from '../Clinic/Clinicmaster';
import HospitalsMaster from '../Hospitals/Hospitalsmaster';
import ChemistMaster from '../Chemist/Chemistmaster';
import StockistMaster from '../Stockist/Stockistmaster';
import CipMaster from '../Cip/Cipmaster';
import Cipdepartment from '../Cip/Cipdepartment';
import Cipdesignation from '../Cip/Cipdesignation';
import Cipqualification from '../Cip/Cipqualification';
import Userteritorymaster from '../Usermaster/Area/Userteritorymaster';
import Userdoctormaster from '../Usermaster/Doctor/Userdoctormaster';
import Userchemistmaster from '../Usermaster/Chemist/USerchemistmaster';
import Userstockitmaster from '../Usermaster/Stockist/Userstockistmaster';
import Userclinicmaster from '../Usermaster/Clinic/Userclinicmaster';
import Userhospitalmaster from '../Usermaster/Hospital/Userhospitalmaster';
import Tourprogram from '../Tourprogram/Tourprogram';
import UserMaster from '../User/usermaster';
import Dayinout from '../Dayinout/Dayinout';
import Dcr from '../Dcr/Dcr';
import Leaverequest from '../Leaverequest/Leaverequest';
import UserRole from '../User/userrole';
import Usermanager from '../User/Usermanager';
import { Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { GetAllUser } from '../../utils/proxy/Usermasterproxy';
import { TransferUserData } from '../../utils/proxy/DataTransferProxy';
import Swal from 'sweetalert2';
import Usercipmaster from '../Usermaster/Cip/Usercipmaster';
import $ from 'jquery';

import Offcanvas from 'react-bootstrap/Offcanvas';

// icons

import { HiHome } from 'react-icons/hi'
import { ImBooks } from 'react-icons/im'
import { GiNotebook, GiNurseMale } from 'react-icons/gi'
import { FaHospitalAlt } from 'react-icons/fa'
import { FaClinicMedical } from 'react-icons/fa'
import { FaDiagramProject, FaPersonBurst } from 'react-icons/fa6'
import { SlChemistry } from 'react-icons/sl'
import { TbBuildingWarehouse } from 'react-icons/tb'
import { RiUserLocationFill } from 'react-icons/ri'
import { FaMagnifyingGlassLocation } from 'react-icons/fa6'
import { BsPersonVideo2 } from 'react-icons/bs'
import { BsPersonFill } from 'react-icons/bs'
import { GrUserManager } from 'react-icons/gr'
import { FaUserTie } from 'react-icons/fa'
import { TbTournament } from 'react-icons/tb'
import { BiSolidReport, BiTask } from 'react-icons/bi'
import { RiUserLocationLine } from 'react-icons/ri'
import { GrTransaction } from 'react-icons/gr'
import { BiTransferAlt } from 'react-icons/bi'
import { SlCalender } from 'react-icons/sl'
import { BiLogOut } from 'react-icons/bi'
import { IoMdOptions } from 'react-icons/io'
import { MdLibraryBooks, MdNotificationAdd } from "react-icons/md";
import { IoCartOutline, IoHomeOutline, IoPersonSharp } from "react-icons/io5";
import { TbCalendarTime } from "react-icons/tb";
import { BsFileEarmarkBarGraphFill } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { FaRegCalendarDays } from "react-icons/fa6";
import { BiCalendarExclamation } from "react-icons/bi";
import { HiDotsVertical } from "react-icons/hi";
import { MdLeaderboard } from "react-icons/md";

import Dcryearly from '../Dcr/Dcrmonthly';
import Dcrmonthly from '../Dcr/Dcrmonthly';
import Userdesignation from '../User/Userdesignation';
import Taskpage from '../TaskManager/Taskpage';
import Expense from '../ExpenseManager/Expense';
import Expensetypemaster from '../ExpenseManager/Expensetypemaster';
import Currencymaster from '../ExpenseManager/Currencymaster';
import Attendance from '../Attendance/Attendance';
import HolidayMaster from '../Attendance/HolidayMaster';
import HolidayTypeMaster from '../Attendance/HolidayTypeMaster';
import Notice from '../Notice/Notice';
import Usertype from '../User/Usertype';
import Project from '../Project/Project';
import TaskCategoryMaster from '../TaskManager/TaskCategoryMaster';
import LeaveTypeMaster from '../Hr/LeaveTypeMaster';
import DeductionMaster from '../Hr/DeductionMaster';
import UserPerfomance from '../Hr/UserPerfomance';
import { CheckDayEnded, DayEndMood, DayStartMood, EndDay, IsStartDay, StartDay } from '../../utils/proxy/Tourplanproxy';
import UserPerformancePage from '../Hr/UserPerformancePage';
import { Permissions, profilePicFunc } from '../../utils/Permissions';
import { GetPermissionsByUserid } from '../../utils/proxy/UserPermissionsProxy';
import NotFound from '../NotFound';
import UserPermission from '../UserPermission.jsx';
import { ResetPassword } from '../../utils/proxy/Authproxy';
import Rolemaster from '../User/Rolemaster';
import DcrReports from '../Dcr/DcrReports';
import Helper from '../../utils/helperFunctions';
import { TpAchievements } from '../Dcr/TpAchievements';
import PobWiseReportForMonths from '../Reports/PobWiseReportForMonths';
import { DcrNotSubmitted } from '../Dcr/DcrNotSubmitted';
import { DcrPendingForAddition } from '../Dcr/DcrPendingForAddition';
import { DcrStateWise } from '../Dcr/DcrStateWise';
import { UsersList } from '../Dcr/UsersList';
import { DcrCountReport } from '../Dcr/DcrCountReport';
import { FieldForceStatus } from '../Dcr/FieldForceStatus';
import { VisitAnalysisReport } from '../Reports/VisitAnalysisReport';
import { ListedDoctorCategory } from '../Reports/ListedDoctorCategory';
import { TerritoryStatusReport } from '../Reports/TerritoryStatusReport';
import { NotesPage } from '../Notes/NotesPage';
import { LeadSalutation } from '../Sales/Leads/LeadSalutation';
import { LeadStatus } from '../Sales/Leads/LeadStatus';
import { LeadSource } from '../Sales/Leads/LeadSource';
import { Industry } from '../Sales/Leads/Industry';
import Leads from '../Sales/Leads/Leads';
import { ProductsCategory } from '../Products/ProductsCategory';
import { ProductMaster } from '../Products/ProductMaster';
import { AccountType } from '../Products/AccountType';
import { Accounts } from '../Products/Accounts';
import { CompanyContacts } from '../Products/CompanyContacts';
import { Settingss } from '../Settingss';
import { UserDashboard2 } from '../UserDashboard2';
import { DeleteNotification, GetAllNotificationByUserid, ReadAllNotification } from '../../utils/proxy/NotificationProxy';
import { NotificationModal } from './NotificationModal';
import { ProfilePage } from '../ProfilePage';
import { QuoteModal } from '../Sales/Leads/QuoteModal';
import { QuoteMaster } from '../Sales/Leads/QuoteMaster';
import CreatePurchaseInvoice from '../purchase/CreatePurchaseInvoice.jsx';
import { UserMoods } from '../../utils/constant.js';
import Taxoptions from '../Taxoptions/Taxoptions.jsx';
import AttendanceSheet from '../Attendance/AttendanceSheet';
import MoodReport from '../Reports/MoodReport.js';
import { InvoiceMaster } from '../Sales/Leads/InvoiceMaster.js';
import { QuoteEdit } from '../Sales/Leads/QuoteEdit.js';
import ProjectWork from '../Project/ProjectWork';


function Menu({ per, logoutUser }) {
  
  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false);
  const [toastShow, setToastShow] = useState(false);

  const [FirstUser, setFirstUser] = useState();
  const [FirstUserId, setFirstUserId] = useState();
  const [SecondUser, setSecondUser] = useState();
  const [SecondUserId, setSecondUserId] = useState();
  const [loadingUsers, setLoadingUsers] = useState(true);

  const [toggle, setToggle] = useState(localStorage.getItem('startdayid') != null && localStorage.getItem('startday') != null);
  const [showEndDayModal, setShowEndDayModal] = useState(false);
  const [remark, setRemark] = useState();
  const [isStarted, setIsStarted] = useState([]);
  const [reload, setReload] = useState(false);


  // Menu Open Close
  const [masterOpen, setMasterOpen] = useState(false);
  const [fieldForceOpen, setFieldForceOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [hrOpen, setHrOpen] = useState(false);
  const [leadOpen, setLeadOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);

  const [passwordModal, setPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassoword] = useState('');

  const [startDayTime, setStartDayTime] = useState(null);
  const [hoursWorked, setHoursWorked] = useState(0);
  const [moodModal, setMoodModal] = useState(false);
  const [selectedMood, setSelectedMood] = useState('');
  const [expandmenu,setExpandmenu] = useState(false);
  const [expandmenutext,setExpandmenutext] = useState(false);

  const [notification, setNotification] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const [newNotification, setNewNotification] = useState(false);

  const [profile, setProfile] = useState(localStorage.getItem('image') ? localStorage.getItem('image')+ `?timestamp=${new Date().getTime()}` : 'assets/images/users/400.png');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  const moods = UserMoods;

              function handleMenuHover(params) {
                setExpandmenu(params);
                
                setTimeout(() => {
                  setExpandmenutext(params);
                }, 300);
                if(!params){
                  setMasterOpen(params);
                  setFieldForceOpen(params);
                  setReportsOpen(params);
                  setOptionsOpen(params);
                  setHrOpen(params);
                  setLeadOpen(  params);
                  setProductOpen(params);
                }
              }




  $(document).ready(function () {
    

    // Close all visible sub-menus when the user clicks anywhere else on the page.
    $(document).click(function (e) {
      if (!$(e.target).closest('.has_sub').length) {
        setMasterOpen(false);
        setFieldForceOpen(false);
        setReportsOpen(false);
        setOptionsOpen(false);
        setHrOpen(false);
      }
    });
  });




  function visibleSubMenuClose() {
    // Iterate over all elements with the class `has_sub`.
    $('.has_sub').each(function () {
      // Get a jQuery object representing the current element.
      var t = $(this);

      // Check if the element has the class `nav-active`.
      if (t.hasClass('nav-active')) {
        // Use the `slideUp()` method to slide up the sub-menu.
        t.find('> ul').slideUp(300, function () {
          // Once the sub-menu is hidden, remove the `nav-active` class from the element.
          t.removeClass('nav-active');
        });
      }
    });
  }

  function adjustMainContentHeight() {
    // Adjust main content height
    var docHeight = $(document).height();
    if (docHeight > $('.body-content').height())
      $('.body-content').height(docHeight);
  }

  useEffect(() => {
    $(document).ready(function () {
      // Get the sidebar-inner element.
      var sidebarInner = $('.sidebar-inner');

      // Set the overflow property of the sidebar-inner element to auto.
      sidebarInner.css('overflow', 'auto');

      // Add a scroll event handler to the sidebar-inner element.
      sidebarInner.scroll(function (event) {
        // Prevent the default scroll behavior.
        event.preventDefault();

        // Get the current scroll position of the sidebar-inner element.
        var scrollTop = sidebarInner.scrollTop();

        // If the scroll position is at the top of the sidebar-inner element,
        // disable the scrollbar.
        if (scrollTop === 0) {
          sidebarInner.css('overflow-y', 'scroll');
          sidebarInner.css('overflow-x', 'hidden');
        }

        // If the scroll position is at the bottom of the sidebar-inner element,
        // disable the scrollbar.
        if (scrollTop + sidebarInner.height() === sidebarInner.get(0).scrollHeight) {
          sidebarInner.css('overflow-y', 'scroll');
          sidebarInner.css('overflow-x', 'hidden');

        }
      });
    });
  }, [])


  useEffect(() => {
    $(document).ready(function () {
      var $this = this;
      $('.button-menu-mobile').on('click', function (event) {
        event.preventDefault();
        $('body').toggleClass("fixed-left-void");
        $('#wrapper').toggleClass("enlarged");
      });
    })
  }, [])


  useEffect(() => {
    const isPresent = async () => {
      let d = await IsStartDay(new Date().toISOString().split('T')[0], localStorage.getItem('uuid'));
      if (d) {
        setIsStarted(d);
        d.map(item => {
          if (item.endday == null && item.startday != null) {
            try {
              localStorage.setItem('startdayid', item.id.toString());
              localStorage.setItem('startday', item.startday);
              setStartDayTime(item.startday)
              setToggle(true);
            } catch (error) {

            }
          } else if (item.endday != null && item.startday != null) {
            setToggle(false);
            localStorage.removeItem('startdayid');
            localStorage.removeItem('startday');
          }
        })
      }
    }

    isPresent();
    setReload(false);
  }, [reload])

  useEffect(() => {
    // Fetch data from an API when the component mounts
    if (show) {
      const getAllUsersData = async () => {
        let users = await GetAllUser();
        if (users) {
          setUser(users);
          setLoadingUsers(false);
        } else {
          alert("Error", "Issue While Fetching Data");
        }
      };
      getAllUsersData();
    }
  }, [show]);

  useEffect(() => {
    const checkendday = async () => {
      let d = await CheckDayEnded(localStorage.getItem('startdayid'));
      if (d.status === 'success') {
        localStorage.removeItem('startdayid');
        localStorage.removeItem('startday');
        setToggle(false);
        setReload(true);
        setStartDayTime(null)
      }
    }
    if (localStorage.getItem('startdayid') != null) {
      checkendday();
    }
  }, [])

  useEffect(() => {
    const calculateHoursWorked = () => {
      if (!startDayTime) return;

      const start = new Date(startDayTime);
      const now = new Date();

      // Calculate the difference in milliseconds
      const difference = now - start;

      // Convert milliseconds to hours, minutes, and seconds
      const totalSeconds = Math.floor(difference / 1000);
      const totalMinutes = Math.floor(totalSeconds / 60);
      const totalHours = Math.floor(totalMinutes / 60);

      const remainingMinutes = totalMinutes % 60;
      const remainingSeconds = totalSeconds % 60;

      setHoursWorked(`${Helper.addLeadingZero(totalHours)}:${Helper.addLeadingZero(remainingMinutes)}:${Helper.addLeadingZero(remainingSeconds)}`);
    };

    const interval = setInterval(calculateHoursWorked, 1000); // Update every second
    return () => clearInterval(interval);
  }, [startDayTime]);

  useEffect(()=>{
    const getNotifications = async() =>{
      let d = await GetAllNotificationByUserid(localStorage.getItem('uuid'),'unread','');
      if(d){
        setNotification(d);
      }
    }
    getNotifications();
    setNewNotification(false)
  },[newNotification])

  let socket ;

  useEffect(()=>{
    socket = new WebSocket(`wss://${localStorage.getItem("REPORTING_URL").split('//')[1]}/ws?userId=` + localStorage.getItem('uuid'))

    socket.onmessage = function(event) {
      setNewNotification(true)
      setToastShow(true);
    };

    return () =>{
      socket.close()
    }

  },[])

  const handleNotification = () =>{
    setNotificationModal(true)
  }

  const handleFirstSelectChange = (selected) => {
    setFirstUserId(selected.value);
  };

  const handleSecondSelectChange = (selected) => {
    setSecondUserId(selected.value);
  };

  const handleSave = async () => {
    let response = await TransferUserData(FirstUserId, SecondUserId);
    if (response) {

      setShow(false);

      Swal.fire({
        title: "Success!",
        text: `User Data Transfer Successfully !!`,
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      alert("Error", "Issue While Fetching Data");
    }
  };

  const options = [];

  user.map((user) => {
    options.push({
      value: user.userid,
      label: user.username + "(" + user.firstname + " " + user.lastname + ")",
    });
  });

  const handleReadAll = async() => {

    let userID = localStorage.getItem('uuid');
    let response = await ReadAllNotification(userID);
    
    if(response){
       if(response.status==='success'){
         Swal.fire({
           title: "Success",
           text: response.message,
           icon: "success",
           confirmButtonText: "OK",
         })
         setNewNotification(true); 
         ;}
         else{
         Swal.fire({
           title: "Faliure",
           text: response.message,
           icon: "error",
           confirmButtonText: "OK",
         });}
     } 
 };

  const handleStartDay = async () => {
    if (toggle == false && isStarted.length == 0) {
      setToggle(!toggle);
      let res = await StartDay(localStorage.getItem('uuid'));
      if (res) {
        try {
          localStorage.setItem('startdayid', res.id.toString());
          localStorage.setItem('startday', res.startday);
          setStartDayTime(res.startday)
          setMoodModal(true);
        } catch (error) {

        }
      }
    } else if (toggle == false && isStarted.length > 0) {
      isStarted.map(item => {
        if (item.endday != null) {
          Swal.fire({
            title: "Warning!",
            text: "Your Day Has Already Ended !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
    } else if (toggle == true && isStarted.length == 0) {
      if (!localStorage.getItem('startday')) {
        Swal.fire({
          title: "Warning!",
          text: "First Start the Day !!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else {
        setShowEndDayModal(true);
        setReload(true);
      }
    } else if (toggle == true && isStarted.length > 0) {
      isStarted.map(item => {
        if (item.endday == null) {
          setShowEndDayModal(true);
          setReload(true);
        } else {
          Swal.fire({
            title: "Warning!",
            text: "Your Day Has Already Ended !!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      })
    }
  }

  const submitEndDay = async () => {
    if (selectedMood == '') {
      Swal.fire({
        title:'Warning',
        text:'Select Mood',
        icon:'warning'
      })
    }else{
      let res = await EndDay(parseInt(localStorage.getItem('startdayid')), remark);
      let res2 = await DayEndMood(parseInt(localStorage.getItem('startdayid')),selectedMood);
      if (res && res2.status == 'success') {
        setShowEndDayModal(false);
        localStorage.removeItem('startday');
        localStorage.removeItem('startdayid');
        setToggle(!toggle);
        setRemark(null);
        setReload(true);
        setStartDayTime(null)
        setSelectedMood('');
        // setStartDay(null); 
        Swal.fire({
          title: "Success!",
          text: "Day Ended !!",
          icon: "success",
          confirmButtonText: "OK",
        });
      }else{
        Swal.fire({
          title:'Error',
          text:"Something Went Wrong",
          icon:'error'
        })
      }
    }
  }

  const resetPwd = async () => {
    if (newPassword == null || newPassword == '') {
      Swal.fire({
        title: "Warning!",
        text: "Enter New Password !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (confirmPassword == null || confirmPassword == '') {
      Swal.fire({
        title: "Warning!",
        text: "Enter Confirm Password !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else if (confirmPassword != newPassword) {
      Swal.fire({
        title: "Warning!",
        text: "New Password And Confirm Password Should Be Same !!",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      let d = await ResetPassword(confirmPassword);
      if (d) {
        Swal.fire({
          title: "Success!",
          text: "Password Changed !!",
          icon: "success",
          confirmButtonText: "OK",
        });
        setPasswordModal(false);
        setNewPassword('');
        setConfirmPassoword('');
        localStorage.clear();
        logoutUser();
      }
    }
  }

  const handleStaryDayMood = async(mood) =>{
    Swal.showLoading();
    let d = await DayStartMood(parseInt(localStorage.getItem('startdayid')),mood);
    if(d.status == 'success'){
      setMoodModal(false);
      Swal.hideLoading();
      Swal.fire({
        title:'Day Started',
        text:'Have a Great Day !!',
        icon:'success'
      })
    }
  }



  return (
    <>
      <BrowserRouter>
        {['sm'].map((expand) => (
          <div className="left side-menu" style={{width:expandmenu ? 240 : 80,boxShadow:"rgb(161 160 160 / 61%) 8px -2px 20px 1px"}} onMouseOver={() => handleMenuHover(true)} onMouseLeave={() => handleMenuHover(false)}>
            <button
              type="button"
              className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
            >
              <i className="ion-close" />
            </button>
            <div style={{ backgroundColor: "#fff" }} className="topbar-left">
              <div className="text-center">
              <a to="/" className="logo">
                  {expandmenutext ? <img src={process.env.PUBLIC_URL + "/images/nav2.png"} alt="Team Insights" width={"100%"} style={{transitionDelay:4,padding:5}} /> : <img src={process.env.PUBLIC_URL + "/images/favicon1.png"} alt="Team Insights" width={50} />}
                </a>
              </div>
            </div>
            <div className="sidebar-inner niceScrollleft">
              <div id="sidebar-menu">
                <ul>
                  <li>
                    <Link className="waves-effect font-weight-bold" to="/"><IoHomeOutline className='mjhaicon' /> {expandmenu && "Dashboard"}</Link>

                  </li>
                  {per.VISIBLE_MASTERS ? (

                    <li className={masterOpen ? 'has_sub nav-active' : 'has_sub'}>
                      <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setMasterOpen(!masterOpen); setFieldForceOpen(false); setReportsOpen(false); setOptionsOpen(false); setHrOpen(false); setProductOpen(false) }}>
                        <MdLibraryBooks className='mjhaicon' /> <span> {expandmenu && "Masters"} </span>
                        {expandmenu && <span className="float-right">
                          <i className="mdi mdi-chevron-right" />
                        </span>}
                      </a>
                      <ul className="list-unstyled">
                        {per.VISIBLE_MASTERS_DOCTOR && <li> <Link to="doctor-master">{expandmenu && "Doctors"} </Link> </li>}
                        {per.VISIBLE_MASTERS_HOSPITAL && <li> <Link to="hospital-master">{expandmenu && "Hospitals"} </Link> </li>}
                        {per.VISIBLE_MASTERS_CLINIC && <li> <Link to="clinic-master">{expandmenu && "Clinics"} </Link> </li>}
                        {per.VISIBLE_MASTERS_CIP && <li> <Link to="cip-master">{expandmenu && "CIP's"} </Link> </li>}
                        {per.VISIBLE_MASTERS_CHEMIST && <li> <Link to="chemist-master">{expandmenu && "Chemists" }</Link> </li>}
                        {per.VISIBLE_MASTERS_STOCKIST && <li> <Link to="stockist-master">{expandmenu && "Stockists" }</Link> </li>}
                        {per.VISIBLE_MASTERS_TERITORY && <li> <Link to="area-master">{expandmenu && "Territories" }</Link> </li>}
                        {per.VISIBLE_MASTERS_CURRENCY && <li> <Link to="currency-master">{expandmenu && "Currencies" }</Link> </li>}
                        {per.VISIBLE_MASTERS_ROLE && <li> <Link to="role-master">{expandmenu && "Role" }</Link> </li>}
                        {per.VISIBLE_MASTERS_EXPENSETYPE && <li> <Link to="expensetype-master">{expandmenu && "Expense Types"} </Link> </li>}
                        {per.VISIBLE_MASTERS_HOLIDAYTYPE && <li> <Link to="holidaytype-master">{expandmenu && "Holiday Types"} </Link> </li>}
                        {per.VISIBLE_MASTERS_HOLIDAY && <li> <Link to="holiday-master">{expandmenu && "Holidays"} </Link> </li>}
                        {per.VISIBLE_MASTERS_TASKCATEGORY && <li> <Link to="taskcategory-master">{expandmenu && "Task Categories"} </Link> </li>}
                        {per.VISIBLE_MASTERS_QUOTEMASTER && <li><Link to="quote-master">{expandmenu && "Quote Master"} </Link> </li>}
                        {per.VISIBLE_MASTERS_MOODREPORT && <li><Link to="mood-report">{expandmenu && "Mood Report"} </Link> </li>}
                      </ul>
                    </li>
                  ) : ("")}
                  {per.VISIBLE_FIELD_FORCE ? (
                    <li className={fieldForceOpen ? 'has_sub nav-active' : 'has_sub'}>
                      <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setFieldForceOpen(!fieldForceOpen); setMasterOpen(false); setReportsOpen(false); setOptionsOpen(false); setHrOpen(false); setProductOpen(false) }}>
                        <RiUserLocationFill className='mjhaicon' /> <span>{expandmenu && "Users"}</span>{" "}
                        {expandmenu && <span className="float-right">
                          <i className="mdi mdi-chevron-right" />
                        </span>}
                      </a>
                      <ul className="list-unstyled">
                      <li> <Link to="salutation-master">{expandmenu && "Saluation Master"}</Link> </li>
                        {per.VISIBLE_FIELD_FORCE_USER_MASTER && <li> <Link to="user-master">{expandmenu && "User Master"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_MANAGERS && <li> <Link to="user-manager">{expandmenu && "Managers"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_ROLE && <li> <Link to="user-role">{expandmenu && "User Roles"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_TYPE && <li> <Link to="user-type">{expandmenu && "User Types"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_DOCTORS && <li> <Link to="user-doctor-master">{expandmenu && "User Doctors"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_HOSPITAL && <li> <Link to="user-hospital-master">{expandmenu && "User Hospitals"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_CLINIC && <li> <Link to="user-clinic-master">{expandmenu && "User Clinics"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_CIP && <li> <Link to="user-cip-master">{expandmenu && "User CIP's"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_CHEMIST && <li> <Link to="user-chemist-master">{expandmenu && "User Chemists"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_STOCKIST && <li> <Link to="user-stockist-master">{expandmenu && "User Stockists"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_LOCATION && <li> <Link to="user-location">{expandmenu && "User Locations"}</Link> </li>}
                        {per.VISIBLE_FIELD_FORCE_USER_TERITORY && <li> <Link to="user-teritory">{expandmenu && "User Territories"}</Link> </li>}
                        {per.USER_PERMISSION && <li> <Link to="user-permission">{expandmenu && "User Permissions"}</Link> </li>}

                      </ul>
                    </li>
                  ) : ("")}
                  {per.VISIBLE_TOUR_PROGRAM ? (
                    <li>
                      <Link to="tour-program" className="waves-effect font-weight-bold">
                        <TbCalendarTime className='mjhaicon' />
                        {expandmenu && <span> Tour Program </span>}
                      </Link>
                    </li>
                  ) : ("")}
                  {per.VISIBLE_REPORTS ? (
                    <li className={reportsOpen ? 'has_sub nav-active' : 'has_sub'}>
                      <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setReportsOpen(!reportsOpen); setFieldForceOpen(false); setMasterOpen(false); setOptionsOpen(false); setHrOpen(false); setProductOpen(false) }}>
                        <BsFileEarmarkBarGraphFill className='mjhaicon' /> {expandmenu && <span> Reports </span>}
                      {expandmenu &&  <span className="float-right">
                          <i className="mdi mdi-chevron-right" />
                        </span>}
                      </a>
                      <ul className="list-unstyled">
                        {per.VISIBLE_REPORTS_DCR_DAILY && <li> <Link to="dailycallrecord">{expandmenu && "DCR (Daily)"}</Link> </li>}
                        {per.VISIBLE_REPORTS_DCR_MONTHLY && <li> <Link to="dailycallrecordmonthly">{expandmenu && "DCR (Monthly)"}</Link> </li>}
                        {per.VISIBLE_REPORTS_DCR_MONTHLY && <li> <Link to="dailycallrecordreports">DCR Reports</Link> </li>}
                        {per.VISIBLE_REPORTS_DCR_INOUT && <li> <Link to="day-in-out">{expandmenu && "Day In/Out"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="tp-achievement">{expandmenu && "Tp Vs Achievement"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="dcr-notsubmitted">{expandmenu && "DCR Not Submitted"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="pob-report">{expandmenu && "POB Report"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="dcr-pendingforadd">{expandmenu && "DCR Pending For Addition"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="dcr-userlist">{expandmenu && "User List"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="dcr-statewise">{expandmenu && "DCR Count State Wise"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="dcr-countreport">{expandmenu && "DCR Count Report"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="fieldforcestatus">{expandmenu && "FieldForce Status Report"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="visit-analysis">{expandmenu && "Visit Analysis"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="doctor-category">{expandmenu && "Listed Doctor Category"}</Link> </li>}
                        {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <li> <Link to="territory-status">{expandmenu && "Territory Status"}</Link> </li>}
                      </ul>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_OPTIONS ? (
                    <li className={optionsOpen ? 'has_sub nav-active' : 'has_sub'}>
                      <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setOptionsOpen(!optionsOpen); setFieldForceOpen(false); setReportsOpen(false); setMasterOpen(false); setHrOpen(false); setProductOpen(false) }}>
                        <IoMdOptions className='mjhaicon' /> {expandmenu &&<span> Options </span>}{" "}
                        {expandmenu && <span className="float-right">
                          <i className="mdi mdi-chevron-right" />
                        </span>}
                      </a>
                      <ul className="list-unstyled">
                        {per.VISIBLE_OPTIONS_DOCTOR_QUALIFICATION && <li> <Link to="doctor-degree">{expandmenu && "Dr. Qualification Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_DOCTOR_SPECIALITY && <li> <Link to="doctor-specialization">{expandmenu && "Dr. Speciality Master"}</Link> </li>}
                        {/* <hr /> */}
                        {per.VISIBLE_OPTIONS_COUNTRY && <li> <Link to="country-master">{expandmenu && "Country Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_STATE && <li> <Link to="state-master">{expandmenu && "State Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_CITY && <li> <Link to="city-master">{expandmenu && "City Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_USER_DESIGNATION && <li> <Link to="user-designation">{expandmenu && "User Designation"}</Link> </li>}
                        {/* <hr /> */}
                        {per.VISIBLE_OPTIONS_CIP_DEPARTMENT && <li> <Link to="cip-department">{expandmenu && "CIP Department Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_CIP_DESIGNATION && <li> <Link to="cip-designation">{expandmenu && "CIP Designation Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_CIP_QUALIFICATION && <li> <Link to="cip-qualification">{expandmenu && "CIP Qualification Master"}</Link> </li>}
                        {per.VISIBLE_OPTIONS_TRANSFER_USER_DATA && <li> <Link onClick={handleShow}>{expandmenu && "Transfer User Data"}</Link> </li>}
                        {/* <hr/> */}
                
                        <li> <Link to="industry-master">{expandmenu && "Industries Master"}</Link> </li>
                        {per.VISIBLE_SETTINGS && <li> <Link to="settings">{expandmenu && "Settings"}</Link> </li>}
                        {per.VISIBLE_TAX_OPTIONS && <li> <Link to="tax-options">{expandmenu && "Tax Options"}</Link> </li>}
                      </ul>
                    </li>
                  ) : ("")}
                  {per.VISIBLE_HR ? (
                    <>
                      <li className={hrOpen ? 'has_sub nav-active' : 'has_sub'}>
                        <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setHrOpen(!hrOpen); setFieldForceOpen(false); setReportsOpen(false); setOptionsOpen(false); setMasterOpen(false); setProductOpen(false) }}>
                          <IoPersonSharp className='mjhaicon' /> {expandmenu && <span> HR </span>}
                        {expandmenu &&  <span className="float-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>}
                        </a>
                        <ul className="list-unstyled">
                          {per.VISIBLE_HR_LEAVE_TYPE && <li> <Link to="leavetype-master">{expandmenu && "Leave Type Master"}</Link> </li>}
                          {per.VISIBLE_HR_DEDUCTION && <li> <Link to="deduction-master">{expandmenu && "Deduction Master"}</Link> </li>}
                          {per.VISIBLE_HR_ATTENDANCE_SHEET && <li> <Link to="attendance-sheet">{expandmenu && "Attendance Sheet"}</Link> </li>}
                          {per.VISIBLE_HR_PERFORMANCE && <li> <Link to="user-perfomance">{expandmenu && "Perfomance"}</Link> </li>}
                        </ul>
                      </li>
                    </>
                  ) : ("")}

{per.VISIBLE_SALES ? (
                    <>
                      <li className={leadOpen ? 'has_sub nav-active' : 'has_sub'}>
                        <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setLeadOpen(!leadOpen); setFieldForceOpen(false); setReportsOpen(false); setOptionsOpen(false); setMasterOpen(false);setHrOpen(false) }}>
                          <MdLeaderboard  className='mjhaicon' /> {expandmenu && <span> Sales </span>}{" "}
                          {expandmenu && <span className="float-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>}
                  </a>
                        <ul className="list-unstyled">
                          {per.VISIBLE_SALES_LEADS && <li> <Link to="leads">{expandmenu && "Leads"}</Link> </li>}
                          {per.VISIBLE_SALES_LEAD_SOURCE && <li> <Link to="leadsource-master">{expandmenu && "Lead Source"}</Link> </li>}
                          {per.VISIBLE_SALES_LEAD_STATUS && <li> <Link to="leadstatus-master">{expandmenu && "Lead Status"}</Link> </li>}
                          {per.VISIBLE_ACCOUNTS && <li> <Link to="accounts">{expandmenu && "Accounts"}</Link> </li>}
                          {per.VISIBLE_COMPANY_CONTACTS && <li> <Link to="company-contacts">{expandmenu && "Contacts"}</Link> </li>}
                          {per.VISIBLE_MASTERS_QUOTEMASTER && <li> <Link to="quote-master">{expandmenu && "Quotes"}</Link> </li>}
                        </ul>
                      </li>
                    </>
                  ) : ("")}

                  {per.VISIBLE_PRODUCTS ? (
                    <>
                      <li className={productOpen ? 'has_sub nav-active' : 'has_sub'}>
                        <a href="javascript:void(0);" className="waves-effect font-weight-bold" onClick={() => { setProductOpen(!productOpen); setHrOpen(false); setFieldForceOpen(false); setReportsOpen(false); setOptionsOpen(false); setMasterOpen(false) }}>
                          <IoCartOutline className='mjhaicon' /> {expandmenu && <span> Products </span>}{" "}
                          {expandmenu && <span className="float-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>}
                        </a>
                        <ul className="list-unstyled">
                         
                          {per.VISIBLE_PRODUCTS_CATEGORY && <li> <Link to="product-category">{expandmenu && "Product Category"}</Link> </li>}
                          {per.VISIBLE_PRODUCTS_MASTER && <li> <Link to="product-master">{expandmenu && "Product Master"}</Link> </li>}
                        </ul>
                        </li>
                    </>
                  ) : ("")}

                  {per.VISIBLE_NOTICE ? (
                    <li>
                      <Link to="notice" className="waves-effect font-weight-bold">
                        <MdNotificationAdd className='mjhaicon' />
                       {expandmenu && <span> Notice </span>}
                      </Link>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_TASKS ? (
                    <li>
                      <Link to="tasks" className="waves-effect font-weight-bold">
                        <FaTasks className='mjhaicon' />
                        {expandmenu && <span> Tasks </span>}
                      </Link>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_TASKS ? (
                    <li>
                      <Link to="notes" className="waves-effect font-weight-bold">
                        <GiNotebook className='mjhaicon' />
                        {expandmenu &&<span> Notes </span>}
                      </Link>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_EXPENSE ? (
                    <li>
                      <Link to="expenses" className="waves-effect font-weight-bold">
                        <FaCoins className='mjhaicon' />
                        {expandmenu && <span> Expense </span>}
                      </Link>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_PROJECTS ? (
                    <li>
                      <Link to="projects" className="waves-effect font-weight-bold">
                        <FaDiagramProject className='mjhaicon' />
                       {expandmenu && <span> Projects </span>}
                      </Link>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_CALENDAR ? (
                    <li>
                      <Link to="attendance" className="waves-effect font-weight-bold">
                        <FaRegCalendarDays className='mjhaicon' />
                        {expandmenu && <span> Calendar </span>}
                      </Link>
                    </li>
                  ) : ("")}

                  {per.VISIBLE_LEAVE_REQUEST ? (
                    <li>
                      <Link to="leave-request" className="waves-effect font-weight-bold">
                        <BiCalendarExclamation className='mjhaicon' />
                        {expandmenu && <span> Leave Request </span>}
                      </Link>
                    </li>
                  ) : ("")}

                </ul>
              </div>
              <div className="clearfix" />
            </div>{" "}
            {/* end sidebarinner */}
          </div>

        ))}
        <div className="content-page" style={{marginLeft:expandmenu ? 240 : 80,transition: 'all 0.4s ease-in-out',WebkitTransition:'all 0.4s ease-in-out'}} >
          <div class="content">
            {/* Top Bar Start */}
            <div className="topbar">
              <nav style={{ backgroundColor: "#28B3D2" }} className="navbar-custom">
                <ul className="list-inline float-right mb-0">
                <li className="list-inline-item notification-list">
                  <span className="nav-link arrow-none waves-effect">{hoursWorked && startDayTime ? <span><strong>Worked Hours : </strong>{hoursWorked}</span> : ''}</span>
                  </li>
                  <li className="list-inline-item notification-list">
                  <span className="nav-link arrow-none waves-effect">{startDayTime ? <span><strong>Started At : </strong>{Helper.ConvertUtcToLocalTime(startDayTime).split(" ")[1]}</span> : ""}</span>
                  </li>
                  

                  {/* <li className="list-inline-item dropdown notification-list">
                    <a
                      className="nav-link dropdown-toggle arrow-none waves-effect"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      <i className="ti-email noti-icon" />
                      <span className="badge badge-danger noti-icon-badge">5</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-lg">
                      item
                      <div className="dropdown-item noti-title">
                        <h5>
                          <span className="badge badge-danger float-right">745</span>
                          Messages
                        </h5>
                      </div>
                      item
                      <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <div className="notify-icon">
                          <img
                            src="assets/images/users/avatar-2.jpg"
                            alt="user-img"
                            className="img-fluid rounded-circle"
                          />{" "}
                        </div>
                        <p className="notify-details">
                          <b>Charles M. Jones</b>
                          <small className="text-muted">
                            Dummy text of the printing and typesetting industry.
                          </small>
                        </p>
                      </a>
                      item
                      <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <div className="notify-icon">
                          <img
                            src="assets/images/users/avatar-3.jpg"
                            alt="user-img"
                            className="img-fluid rounded-circle"
                          />{" "}
                        </div>
                        <p className="notify-details">
                          <b>Thomas J. Mimms</b>
                          <small className="text-muted">
                            You have 87 unread messages
                          </small>
                        </p>
                      </a>
                      item
                      <a href="javascript:void(0);" className="dropdown-item notify-item">
                        <div className="notify-icon">
                          <img
                            src="assets/images/users/avatar-4.jpg"
                            alt="user-img"
                            className="img-fluid rounded-circle"
                          />{" "}
                        </div>
                        <p className="notify-details">
                          <b>Luis M. Konrad</b>
                          <small className="text-muted">
                            It is a long established fact that a reader will
                          </small>
                        </p>
                      </a>
                      All
                      <a href="javascript:void(0);" className="dropdown-item notify-item">
                        View All
                      </a>
                    </div>
                  </li> */}
                  <li className="list-inline-item dropdown notification-list">
                    <a
                      className="nav-link dropdown-toggle arrow-none waves-effect"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      <i className="ti-bell noti-icon" />
                      <span className="badge badge-success noti-icon-badge">{notification.length > 0 ? notification.length : ''}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-arrow dropdown-menu-lg">
                      <div className="noti-title">
                        <h5>
                          {notification.length > 0 ? <span className="badge badge-danger float-right cursor" onClick={handleReadAll}>Read All</span> : ''}
                          Notification
                        </h5>
                      </div>
                      {notification.length > 0 ? notification.slice(0,3).map(data=>(
                        <Link key={data.id} to={data.link} onClick={async() => {await DeleteNotification(data.id);setNewNotification(true)}} className="dropdown-item notify-item">
                        <div className="notify-icon bg-primary">
                          <i className="mdi mdi-message-text-outline" />
                        </div>
                        <p className="notify-details">
                          <b>{data.title}</b>
                          <small className="text-muted">
                            {data.content}
                          </small>
                        </p>
                        </Link>
                      )):<div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:20}}>No Notification</div>}
                      {notification.length > 3 ? <a href="javascript:void(0);" className="dropdown-item notify-item" onClick={()=>handleNotification()}>
                        View All
                      </a> : null}
                    </div>
                  </li>
                  
                  <li className="list-inline-item dropdown notification-list">
                    <div className="nav-link arrow-none waves-effect">
                      <label class="switch-ti noti-icon">
                        <input type="checkbox" id="togBtn" onClick={handleStartDay} checked={toggle} />
                        <div class="slider-ti"></div>
                      </label></div>
                  </li>
                  <li className="list-inline-item dropdown notification-list">
                    <a
                      className="nav-link dropdown-toggle arrow-none waves-effect nav-user"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      <img
                        src={profile}
                        alt="user"
                        className="rounded-circle"
                      />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right profile-dropdown ">

                      <div className="dropdown-item noti-title">
                        <h5>Welcome</h5>
                      </div>
                      <Link className="dropdown-item" to="/profile">
                        <i className="mdi mdi-account-circle m-r-5 text-muted" /> {localStorage.getItem('firstname') + " " + localStorage.getItem('lastname')}
                      </Link>
                      {/* <a className="dropdown-item" href="#">
                        <i className="mdi mdi-wallet m-r-5 text-muted" /> My Wallet
                      </a>
                      <a className="dropdown-item" href="#">
                        <span className="badge badge-success float-right">5</span>
                        <i className="mdi mdi-settings m-r-5 text-muted" /> Settings
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="mdi mdi-lock-open-outline m-r-5 text-muted" /> Lock
                        screen
                      </a>
                      <div  /> */}
                      <a className="dropdown-item" href="javascript:void(0);" onClick={() => setPasswordModal(true)}>
                        <i className="mdi mdi-logout m-r-5 text-muted" /> Reset Password
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);" onClick={() => logoutUser()}>
                        <i className="mdi mdi-logout m-r-5 text-muted" /> Logout
                      </a>
                    </div>
                  </li>
                </ul>
                <ul className="list-inline menu-left mb-0">
                  <li className="float-left">
                    <button className="button-menu-mobile open-left waves-light waves-effect">
                      <i className="mdi mdi-menu" />
                    </button>
                  </li>
                </ul>
                <div className="clearfix" />
              </nav>
            </div>

            <div class="page-content-wrapper ">
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                {per.VISIBLE_OPTIONS_COUNTRY && <Route path="country-master" element={<Countrymaster />} />}
                {per.VISIBLE_OPTIONS_STATE && <Route path="state-master" element={<Statemaster />} />}
                {per.VISIBLE_OPTIONS_CITY && <Route path="city-master" element={<Citymaster />} />}
                {per.VISIBLE_MASTERS_TERITORY && <Route path="area-master" element={<Areamaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_LOCATION && <Route path="user-location" element={<Userlocation />} />}
                {per.VISIBLE_MASTERS_DOCTOR && <Route path="doctor-master" element={<Doctormaster />} />}
                {per.VISIBLE_OPTIONS_DOCTOR_QUALIFICATION && <Route path="doctor-degree" element={<Doctordegree />} />}
                {per.VISIBLE_OPTIONS_DOCTOR_SPECIALITY && <Route path="doctor-specialization" element={<Doctorspecial />} />}
                {per.VISIBLE_MASTERS_CLINIC && <Route path="clinic-master" element={<Clinicmaster />} />}
                {per.VISIBLE_MASTERS_HOSPITAL && <Route path="hospital-master" element={<HospitalsMaster />} />}
                {per.VISIBLE_MASTERS_CHEMIST && <Route path="chemist-master" element={<ChemistMaster />} />}
                {per.VISIBLE_MASTERS_STOCKIST && <Route path="stockist-master" element={<StockistMaster />} />}
                {per.VISIBLE_MASTERS_CIP && <Route path="cip-master" element={<CipMaster />} />}
                {per.VISIBLE_OPTIONS_CIP_DEPARTMENT && <Route path="cip-department" element={<Cipdepartment />} />}
                {per.VISIBLE_OPTIONS_CIP_DESIGNATION && <Route path="cip-designation" element={<Cipdesignation />} />}
                {per.VISIBLE_OPTIONS_CIP_QUALIFICATION && <Route path="cip-qualification" element={<Cipqualification />} />}
                {per.VISIBLE_FIELD_FORCE_USER_TERITORY && <Route path="user-teritory" element={<Userteritorymaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_DOCTORS && <Route path="user-doctor-master" element={<Userdoctormaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_CHEMIST && <Route path="user-chemist-master" element={<Userchemistmaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_STOCKIST && <Route path="user-stockist-master" element={<Userstockitmaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_CLINIC && <Route path="user-clinic-master" element={<Userclinicmaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_CIP && <Route path="user-cip-master" element={<Usercipmaster />} />}
                {per.VISIBLE_FIELD_FORCE_USER_HOSPITAL && <Route path="user-hospital-master" element={<Userhospitalmaster />} />}
                {per.VISIBLE_TOUR_PROGRAM && <Route path="tour-program" element={<Tourprogram />} />}
                {per.VISIBLE_FIELD_FORCE_USER_MASTER && <Route path="user-master" element={<UserMaster />} />}
                {per.VISIBLE_REPORTS_DCR_INOUT && <Route path="day-in-out" element={<Dayinout />} />}
                {per.VISIBLE_REPORTS_DCR_DAILY && <Route path="dailycallrecord" element={<Dcr />} />}
                {per.VISIBLE_REPORTS_DCR_MONTHLY && <Route path="dailycallrecordmonthly" element={<Dcrmonthly />} />}
                {per.VISIBLE_REPORTS_DCR_MONTHLY && <Route path="dailycallrecordreports" element={<DcrReports />} />}
                {per.VISIBLE_LEAVE_REQUEST && <Route path="leave-request" element={<Leaverequest />} />}
                {per.VISIBLE_FIELD_FORCE_USER_ROLE && <Route path="user-role" element={<UserRole />} />}
                {per.VISIBLE_FIELD_FORCE_USER_TYPE && <Route path="user-type" element={<Usertype />} />}
                {per.VISIBLE_FIELD_FORCE_MANAGERS && <Route path="user-manager" element={<Usermanager />} />}
                {per.VISIBLE_OPTIONS_USER_DESIGNATION && <Route path="user-designation" element={<Userdesignation />} />}
                {per.VISIBLE_TASKS && <Route path="tasks" element={<Taskpage />} />}
                {per.VISIBLE_EXPENSE && <Route path="expenses" element={<Expense />} />}
                {per.VISIBLE_MASTERS_EXPENSETYPE && <Route path="expensetype-master" element={<Expensetypemaster />} />}
                {per.VISIBLE_MASTERS_CURRENCY && <Route path="currency-master" element={<Currencymaster />} />}
                {per.VISIBLE_CALENDAR && <Route path="attendance" element={<Attendance />} />}
                {per.VISIBLE_MASTERS_HOLIDAY && <Route path="holiday-master" element={<HolidayMaster />} />}
                {per.VISIBLE_MASTERS_TASKCATEGORY && <Route path="taskcategory-master" element={<TaskCategoryMaster />} />}
                {per.VISIBLE_MASTERS_ROLE && <Route path="role-master" element={<Rolemaster />} />}
                {per.VISIBLE_MASTERS_HOLIDAYTYPE && <Route path="holidaytype-master" element={<HolidayTypeMaster />} />}
                {per.VISIBLE_HR_LEAVE_TYPE && <Route path="leavetype-master" element={<LeaveTypeMaster />} />}
                {per.VISIBLE_HR_DEDUCTION && <Route path="deduction-master" element={<DeductionMaster />} />}
                {per.VISIBLE_NOTICE && <Route path="notice" element={<Notice />} />}
                {per.VISIBLE_PROJECTS && <Route path="projects" element={<Project />} />}
                {per.VISIBLE_PROJECTS && <Route path="projects-work" element={<ProjectWork />} />}
                {per.VISIBLE_HR_PERFORMANCE && <Route path="user-perfomance" element={<UserPerformancePage />} />}
                {per.USER_PERMISSION  && <Route path="user-permission" element={<UserPermission />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="tp-achievement" element={<TpAchievements />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="dcr-notsubmitted" element={<DcrNotSubmitted />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="pob-report" element={<PobWiseReportForMonths />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="dcr-pendingforadd" element={<DcrPendingForAddition />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="dcr-statewise" element={<DcrStateWise />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="dcr-userlist" element={<UsersList />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="dcr-countreport" element={<DcrCountReport />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="fieldforcestatus" element={<FieldForceStatus />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="visit-analysis" element={<VisitAnalysisReport />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="doctor-category" element={<ListedDoctorCategory />} />}
                {per.VISIBLE_REPORTS_TP_VS_ACHIEVEMENT && <Route path="territory-status" element={<TerritoryStatusReport />} />}
                {per.VISIBLE_TASKS && <Route path="notes" element={<NotesPage />} />}
                <Route path="salutation-master" element={<LeadSalutation />} />
                {per.VISIBLE_SALES_LEAD_STATUS && <Route path="leadstatus-master" element={<LeadStatus />} />}
                {per.VISIBLE_SALES_LEAD_SOURCE && <Route path="leadsource-master" element={<LeadSource />}/>}
                {per.VISIBLE_INDUSTRY_MASTER && <Route path="industry-master" element={<Industry />}/>}
                {per.VISIBLE_SALES_LEADS && <Route path="leads" element={<Leads />} />}
                {per.VISIBLE_PRODUCTS_CATEGORY && <Route path="product-category" element={<ProductsCategory />} />}
                {per.VISIBLE_PRODUCTS_MASTER && <Route path="product-master" element={<ProductMaster />} />}
                {per.VISIBLE_ACCOUNTS_TYPE && <Route path="accounttype-master" element={<AccountType />} />}
                {per.VISIBLE_ACCOUNTS && <Route path="accounts" element={<Accounts />} />}
                {per.VISIBLE_COMPANY_CONTACTS && <Route path="company-contacts" element={<CompanyContacts />} />}
                {per.VISIBLE_SETTINGS && <Route path="settings" element={<Settingss />} />}
                {per.VISIBLE_SETTINGS && <Route path="udb" element={<UserDashboard2 />} />}
                <Route path="profile" element={<ProfilePage setProfile={setProfile} />} />
                {per.VISIBLE_QUOTE_CREATE && <Route path="quotecr" element={<QuoteModal />} />}
                {per.VISIBLE_QUOTE_CREATE && <Route path="quoteedit" element={<QuoteEdit />} />}
                {per.VISIBLE_MASTERS_QUOTEMASTER && <Route path="quote-master" element={<QuoteMaster />} />}
                <Route path="purchaseinvoice" element={<CreatePurchaseInvoice />} />
                <Route path="tax-options" element={<Taxoptions />} />
                <Route path="invoice-master" element={<InvoiceMaster />}/>
                {per.VISIBLE_HR_ATTENDANCE_SHEET && <Route path="attendance-sheet" element={<AttendanceSheet />} />}
                {per.VISIBLE_MASTERS_MOODREPORT && <Route path="mood-report" element={<MoodReport />}/>}

                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
        <NotificationModal notificationModal={notificationModal} setNotificationModal={setNotificationModal} setNewNotification={setNewNotification} />
      </BrowserRouter>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer User Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingUsers ? <>
            <h2>Loading</h2>
          </> : <Form>
            <Form.Group className="mb-3">
              <Form.Label>From <span style={{color:"red"}}>*</span></Form.Label>

              <Select
                className="selectdoctor"
                options={options}
                value={FirstUser}
                onChange={handleFirstSelectChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />

            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>To <span style={{color:"red"}}>*</span></Form.Label>

              <Select
                className="selectdoctor"
                options={options}
                value={SecondUser}
                onChange={handleSecondSelectChange}
                styles={{
   
   dropdownIndicator: (provided) => ({
     ...provided,
     color: '#2457C5' // Change color of the arrow here
   })
 }}
              />

            </Form.Group>
          </Form>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEndDayModal} onHide={() => setShowEndDayModal(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type="text"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Enter Remark"
                required
              />
            </Form.Group>
          </Form>
          <Form.Label>How Was Your Day ?</Form.Label>
          <Row>
          {moods.map(mood => (
            <Col className='waves-effect' style={{border:selectedMood === mood.value ? '1px solid black' : ''}} onClick={(e) => setSelectedMood(mood.value)}>
              <div className='d-flex flex-column align-items-center'>
                <div style={{fontSize:30}}>{mood.mood}</div>
                <span>{mood.value}</span>
              </div>
            </Col>
          ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEndDayModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={submitEndDay}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={passwordModal} onHide={() => setPasswordModal(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter New Password"
                required
              />
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setConfirmPassoword(e.target.value)}
                placeholder="Enter Confirm Password"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPasswordModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={resetPwd}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={moodModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>How is Your Mood Today?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          {moods.map(mood => (
            <Col className='waves-effect' onClick={() => handleStaryDayMood(mood.value)}>
              <div className='d-flex flex-column align-items-center'>
                <div style={{fontSize:30}}>{mood.mood}</div>
                <span>{mood.value}</span>
              </div>
            </Col>
          ))}
          </Row>
        </Modal.Body>
      </Modal>      

     {notification[0] && <ToastContainer
          className="p-3"
          position={'top-end'}
          style={{ zIndex: 10 }}
        >
      <Toast onClose={() => setToastShow(false)} show={toastShow} delay={3000} autohide>
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong className="me-auto">{notification[0].title}</strong>
      </Toast.Header>
      <Toast.Body>{notification[0].content}</Toast.Body>
    </Toast>
    </ToastContainer>}
    </>
  );
}

export default Menu
