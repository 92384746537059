
function PaySlipFormat({month,year,username,paid,absent,leaves,holidays,salary,company,deductions,totaldeduction}) {


    function formatRupees(amount) {
        const formatter = new Intl.NumberFormat('en-IN', {
            // style: 'currency',
            // currency: 'INR',
            maximumFractionDigits: 2
        });
        return formatter.format(amount);
    }

    function calculateAdjustedSalary(salary, totalWorkingDays, absentDays) {
        const dailySalary = salary / totalWorkingDays;
        const deduction = dailySalary * absentDays;
        // const adjustedSalary = salary - deduction;
    
        return Math.round(deduction);
    }
    

    return (
        <div>
            <div style={{ padding: '20px', backgroundColor: '#fff', margin: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{fontSize:22,fontWeight:'bold'}}>{company && company}</div>
                    </div>
                    <div>
                        <div>PaySlip For Month</div>
                        <div style={{fontSize:20,fontWeight:'bold'}}>{month} {year}</div>
                    </div>
                </div>

                <hr />

                <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div>
                        <table width={'100%'}>
                        <tr>
                            <td className="p-2 font-weight-bold">Employee Summary</td>
                            
                        </tr>
                        <tr>
                            <td className="p-2">Employee Name</td>
                            <td>:</td>
                            <td className="p-2 font-weight-bold">{username && username}</td>
                        </tr>
                        <tr>
                            <td className="p-2">Pay Period</td>
                            <td>:</td>
                            <td className="p-2 font-weight-bold">{month} {year}</td>
                        </tr>
                        {/* <tr>
                            <td className="p-2">Pay Date</td>
                            <td>:</td>
                            <td className="p-2 font-weight-bold">1/10/2024</td>
                        </tr> */}
                        </table>
                    </div>
                    <div className="border rounded" style={{ width: "clamp(200px, 50vw, 300px)" }}>
                        <div style={{borderBottom:'1px dashed grey'}}>
                            <div style={{margin:20}}>
                                <div className="font-weight-bold font-20">{formatRupees(((salary - calculateAdjustedSalary(salary, (paid+absent+leaves+holidays),absent)) || 0) - totaldeduction)}</div>
                                <div>Employee Net Pay</div>
                            </div>
                        </div>
                        <div>
                            <table width={'100%'}>
                            <tr>
                                <td className="pl-2">Paid Days</td>
                                <td>:</td>
                                <td className="pl-2 font-weight-bold">{paid}</td>
                            </tr>
                            <tr>
                                <td className="pl-2">Absent Days</td>
                                <td>:</td>
                                <td className="pl-2 font-weight-bold">{absent}</td>
                            </tr>
                            <tr>
                                <td className="pl-2">Holidays</td>
                                <td>:</td>
                                <td className="pl-2 font-weight-bold">{holidays}</td>
                            </tr>
                            <tr>
                                <td className="pl-2">Leaves</td>
                                <td>:</td>
                                <td className="pl-2 font-weight-bold">{leaves}</td>
                            </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div style={{display:'flex',justifyContent:'space-between'}} className="border rounded mt-5">
                    <div style={{width:"clamp(400px, 50vw, 500px)"}} className="m-3">
                    <table width={'100%'}>
                        <thead>
                            <tr>
                                <th className="p-2" style={{borderBottom:'1px dashed grey'}}>Earning</th>
                                <th className="p-2" style={{borderBottom:'1px dashed grey'}}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="p-2">Basic</td>
                                <td className="p-2 font-weight-bold">{salary ? formatRupees(salary) : 0}</td>
                            </tr>
                            {absent > 0 && <tr>
                                <td className="p-2">Absent Days Salary</td>
                                <td className="p-2 font-weight-bold">{formatRupees(calculateAdjustedSalary(salary, (paid+absent+leaves+holidays),absent))}</td>
                            </tr>}
                            <tr style={{background:'#dee2e6'}}>
                                <th className="p-2">Gross Earning </th>
                                <td className="p-2 font-weight-bold">{salary ? formatRupees(salary - calculateAdjustedSalary(salary, (paid+absent+leaves+holidays),absent)) : 0}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                    <div style={{width:"clamp(400px, 50vw, 500px)"}} className="m-3">
                    <table width={'100%'}>
                        <thead>
                            <tr>
                                <th className="p-2" style={{borderBottom:'1px dashed grey'}}>Deductions</th>
                                <th className="p-2" style={{borderBottom:'1px dashed grey'}}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>

                                {deductions?.map((deduction)=>(
                                <tr>
                                <th className="p-2">{deduction.name}</th>
                                <td className="p-2 font-weight-bold">{formatRupees(deduction.amount || 0)}</td>
                                </tr>
                                ))}
                            
                            <tr style={{background:'#dee2e6'}}>
                                <th className="p-2">Total Deductions </th>
                                <td className="p-2 font-weight-bold">{formatRupees(totaldeduction)}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>

                <div className="border rounded" style={{display:'flex',justifyContent:'space-between',marginTop:'inherit'}}>
                    <div>
                        <div className="m-2">
                        <div style={{fontSize:15,fontWeight:'bold'}}>Total Net Payable</div>
                        <div style={{fontSize:12}}>Gross Earnings - Total Deductions</div>
                        </div>
                    </div>
                    <div className="border" style={{display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold',fontSize:20,width:"clamp(100px, 50vw, 200px)"}}>
                        {formatRupees((salary - calculateAdjustedSalary(salary, (paid+absent+leaves+holidays),absent)) - totaldeduction)}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PaySlipFormat;