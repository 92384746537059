import Restapi from "../api/Restapi";
import { constats } from "../constant";

export const createInvoice = async (data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('post',REPORTING_URL+'/apiv1/invoice',data);
}

export const UpdateInvoice = async (id,data) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('put',REPORTING_URL+'/apiv1/invoice/'+id,data);
}

export const DeleteInvoice = async (id) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('delete',REPORTING_URL+'/apiv1/invoice/'+id,null);
}

export const GetAllInvoice = async () => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/invoice',null);
}

export const GetInvoiceByOwnerid = async (ownerid) => {
    const [AUTH_URL, REPORTING_URL] = await constats();
    return await Restapi('get',REPORTING_URL+'/apiv1/invoice/owner/'+ownerid,null);
}
